export const environment = {
  firebaseConfig: {
    "projectId": "portal-da-sorte-3333",
    "appId": "1:5662713604:web:7c0242a0b54992b42d1251",
    "storageBucket": "portal-da-sorte-3333.appspot.com",
    "apiKey": "AIzaSyB1Fj94pFabIHA1fOm69zp2xU8IzljaMcY",
    "authDomain": "portal-da-sorte-3333.firebaseapp.com",
    "messagingSenderId": "5662713604",
    "measurementId": "G-G52R8HRTR1"
  }
};

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { JsonPipe } from '@angular/common';
import { IonContent, IonSegmentButton, IonLabel, IonSegment, IonItem, IonBreadcrumb, IonBreadcrumbs, IonList, IonInput, IonSpinner } from '@ionic/angular/standalone';
import { FormsModule } from '@angular/forms';
import { NgxMaskDirective } from 'ngx-mask';
import { BookieFormAddComponent, BookieFormAddSkeletonComponent } from '../bookie-form-add/bookie-form-add.component';
import { Breadcrumb, BreadcrumbsComponent } from '@common/components';
import { BaseClass, RafflesListComponent, navigateReplace } from '@common/globals';
import { Bookie, RaffleStatus, Raffles, StatusRaffleOptionsArr } from '@common/models';
import { BookieService, RaffleService, ToastService } from '@common/services';

const IonicComponents = [IonInput, IonList, IonBreadcrumbs, IonBreadcrumb, IonItem, IonSegment, IonLabel, IonSegmentButton, IonContent, IonSpinner];

@Component({
  selector: 'app-bookie-details',
  templateUrl: './bookie-details.component.html',
  styleUrls: ['./bookie-details.component.scss'],
  standalone: true,
  imports: [IonicComponents, JsonPipe, RouterLink, BreadcrumbsComponent, NgxMaskDirective, FormsModule, RafflesListComponent, BookieFormAddComponent, BookieFormAddSkeletonComponent],
})
export class BookieDetailsComponent extends BaseClass() implements OnInit {
  bookie: Bookie;

  breadcrumbs: Array<Breadcrumb> = [
    { link: '..', text: 'Rifeiros' },
    { link: null, text: 'Detalhes Rifeiro' },
  ];

  activeSegment: SegmentValue = 'details';

  segments: Array<Segment> = [
    { value: 'details', label: 'Detalhes' }
  ];

  raffles: Raffles;

  statusRaffle: RaffleStatus = 'ACTIVE';

  statusRaffleOptionsArr = StatusRaffleOptionsArr;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private bookieService: BookieService,
    private raffleService: RaffleService,
    private toastService: ToastService,
  ) {
    super();
  }

  ngOnInit() {
    const bookieId = this.route.snapshot?.paramMap?.get('id');
    if (bookieId) {
      this.getBookieDetails(bookieId);
    } else {
      this.bookie = {

      };
    }
  }

  segmentChanged() {
    if (this.activeSegment === 'raffles') this.getRaffles();
  }

  selectedStatusChanged() {
    this.raffles = null;
    this.getRaffles();
  }

  listRaffles(status: RaffleStatus) {
    this.statusRaffle = status;
    this.activeSegment = 'raffles';
    this.getRaffles();
  }

  getBookieDetails(id: string) {
    this.bookieService.getBookie(id).then((res) => {
      if (!res.bookie) this.navigateBookies('Rifeiro não encontrado');
      this.bookie = res.bookie;
      this.segments.push({ value: 'raffles', label: 'Rifas' });
    }).catch((err) => {
      console.log(err);
      this.navigateBookies('Não foi possível carregar os detalhes do rifeiro');
    });
  }

  private navigateBookies(errorMessage: string = null) {
    if (errorMessage) this.toastService.present({ message: errorMessage, color: 'danger' });
    navigateReplace(this.router, ['/', 'home', 'bookies']);
  }

  getRaffles() {
    if (!this.bookie) return;
    this.raffleService.getRafflesByBookieId(this.bookie.id, this.statusRaffle).then((res) => {
      this.raffles = res.raffles;
    });
  }
}

type Segment = {
  value: SegmentValue;
  label: string;
};

type SegmentValue = 'details' | 'raffles';

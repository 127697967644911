<ion-content>
  @if (isDesktop) {
    <div id="header" class="padding-vertical-md padding-inline-md">
      <div id="searchbox">
        <!-- <ion-searchbar #searchbar class="no-padding" (ionChange)="search(searchbar.value)" showClearButton="never" />

      <ion-buttons slot="end">
        <ion-button [color]="isDesktop ? 'primary' : 'danger'">
          <ion-icon name="search" />
        </ion-button>
      </ion-buttons> -->
      </div>

      <ion-buttons>
        <a [routerLink]="['/home', 'bookies', 'new']">
          <ion-button fill="solid">
            <ion-text> Adicionar </ion-text>
            <ion-icon name="add" slot="end" />
          </ion-button>
        </a>
      </ion-buttons>
    </div>
  }

  <div id="content" class="margin-inline-sm">
    <bookies-list [bookies]="bookies" />
  </div>

  @if (isMobile) {
    <ion-fab vertical="bottom" horizontal="end" slot="fixed">
      <a [routerLink]="['/home', 'bookies', 'new']">
        <ion-fab-button color="danger">
          <ion-icon name="add" />
        </ion-fab-button>
      </a>
    </ion-fab>
  }
</ion-content>

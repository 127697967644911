import { Component, Input, OnInit } from '@angular/core';
import { IonList, IonLabel, IonInput, IonButton, IonIcon } from "@ionic/angular/standalone";
import { IonAvatar, IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonCardTitle, IonImg, IonItem } from '@ionic/angular/standalone';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { NgxMaskDirective } from 'ngx-mask';
import { CurrencyPipe, DatePipe } from '@angular/common';
import { AbrNumberPipe, AlertService, BaseClass, CardTotalComponent, LoadingService, RaffleService, ToastService, TotalCard } from '@common/globals';
import { Raffle } from '@common';
import { addIcons } from 'ionicons';
import { lockOpenOutline, trashOutline } from 'ionicons/icons';
import { Router } from '@angular/router';

addIcons({ lockOpenOutline, trashOutline });

const IonicComponents = [IonCardTitle, IonCardHeader, IonCardContent, IonCardSubtitle, IonCard, IonAvatar, IonImg, IonItem, IonList, IonInput, IonLabel, IonIcon, IonButton];
const Pipes = [AbrNumberPipe, CurrencyPipe, DatePipe];

@Component({
  selector: 'section-details-raffle',
  templateUrl: './section-details-raffle.component.html',
  styleUrls: ['../raffle-details.component.scss'],
  standalone: true,
  imports: [IonIcon, IonButton, IonicComponents, Pipes, MatFormFieldModule, MatInputModule, NgxMaskDirective, CardTotalComponent],
})
export class SectionDetailsRaffleComponent extends BaseClass() implements OnInit {

  @Input() raffle: Raffle;

  cards: Array<TotalCard> = [];

  constructor(
    private router: Router,
    private raffleService: RaffleService,
    private toastService: ToastService,
    private loadingService: LoadingService,
    private alertService: AlertService,
  ) {
    super();
  }

  ngOnInit() {
    if (!this.raffle) return;
    this.cards = [
      { label: 'Números', total: this.raffle.amount_numbers, type: 'abr-number' },
      { label: 'Disponíveis', total: this.raffle.qtd_array, type: 'fmt-number' },
      { label: 'Valor da Cota', total: this.raffle.quota_value, type: 'currency' },
      { label: 'Já Vendido', total: this.raffle.total_sellings, type: 'abr-number' },
      { label: 'Status', total: this.raffle.status, type: 'raffle-status' },
      { label: 'Já Arrecadado', total: this.raffle.total_sellings * this.raffle.quota_value, type: 'currency' }
    ];
  }

  async deleteRaffle() {
    const confirm = await this.alertService.confirm("Tem certeza", "Deseja realmente excluir essa rifa? Essa operação não poderá ser desfeita");
    if (!confirm) return;

    const loading = await this.loadingService.presentLoading('Excluindo... aguarde');

    this.raffleService.delete(this.raffle.id).then((res) => {
      if (res.response !== 201) {
        this.alertService.presentAlert('Erro', 'Não foi possível aprovar a rifa no momento, tente novamente');
        return;
      }

      this.toastService.present({ message: 'Rifa excluída com sucesso', color: 'success' });
      this.router.navigate(['home', 'raffles']);
    }).catch((err) => {
      console.log(err);
      this.toastService.present({ message: 'Erro ao excluir a rifa', color: 'danger' });
    }).finally(() => {
      loading.dismiss();
    });
  }

  async approveRaffle() {
    if (this.raffle.status !== 'PENDING') return this.toastService.present({ message: 'Rifa já aprovada', color: 'warning' });

    const confirm = await this.alertService.confirm("Tem certeza", "Deseja realmente aprovar essa rifa?");
    if (!confirm) return;

    const loading = await this.loadingService.presentLoading('Aprovando... aguarde');
    await loading.present();

    this.raffleService.approveRaffle(this.raffle.id).then((res) => {
      if (res.response !== 200) {
        this.alertService.presentAlert('Erro', 'Não foi possível aprovar a rifa no momento, tente novamente');
        return;
      }

      this.raffle.status = 'ACTIVE';
      this.toastService.present({ message: "Rifa aprovada com sucesso", color: 'success' });
    }).catch((err) => {
      console.log(err);
      this.toastService.present({ message: 'Erro ao aprovar o rifa', color: 'danger' });
    }).finally(() => {
      loading.dismiss();
    });
  }

}

import { Injectable } from '@angular/core';
import { FlashNumberRaffle, Raffle, RaffleStatus, Raffles } from '../models/raffle.model';
import { API } from '../api';
import { BaseHttpService } from './base-http.service';
import { TransactionsRaffle } from '../models';

@Injectable({
  providedIn: 'root',
})
export class RaffleService extends BaseHttpService {
  getRaffles() {
    return this.GET<{ message: string; raffles: Raffles }>(API.ENPOINTS.globals.allRaffles);
  }

  getCountRaffles(bookie_id: string = null, status: RaffleStatus = null) {
    const params = {};
    if (status) Object.assign(params, { status });
    if (bookie_id) Object.assign(params, { bookie_id });
    return this.GET<{ message: string; count: number }>(API.ENPOINTS.raffle.count, params);
  }

  getRaffleById(id: string): Promise<Raffle> {
    return this.GET<Raffle>(`${API.ENPOINTS.raffle.raffleById}`, { id });
  }

  getRafflePromotion(id: string): Promise<Raffle> {
    return this.GET_NOAUTH<Raffle>(`${API.ENPOINTS.client.promotion}/${id}`);
  }

  getRafflesByBookieId(bookie_id: string, status: RaffleStatus = null) {
    return this.GET<{ message: string; raffles: Raffles }>(API.ENPOINTS.raffle.rafflesByBookieId, { bookie_id, status });
  }

  getBuyersRaffle(id: string, page: number = 1) {
    return this.GET<TransactionsRaffle>(`${API.ENPOINTS.bookie.buyers}/${id}/${page}`);
  }

  getFlashNumbers(raffleId: string) {
    return this.GET<{ response: number, message: Array<FlashNumberRaffle> }>(`${API.ENPOINTS.bookie.flashNumbersRaffle}/${raffleId}`);
  }

  unlockFlashNumber(raffleId: string, flash_number: number) {
    return this.POST<{ response: number, message: string }>(`${API.ENPOINTS.bookie.unlockFlashNumberRaffle}/?id=${raffleId}`, { flash_number });
  }

  insertFlashNumbers(raffleId: string, flash_numbers: Array<number>) {
    return this.POST<{ response: number, message: string }>(`${API.ENPOINTS.raffle.insertFlashNumbers}/?id=${raffleId}`, { flash_numbers });
  }

  insert(raffle: Partial<Omit<Raffle, 'id'>>) {
    return this.POST<{ id: string, encodeId: string }>(API.ENPOINTS.raffle.create, raffle);
  }

  update(id: string, partialRaffle: Partial<Raffle>) {
    return this.PUT(`${API.ENPOINTS.raffle.update}/?id=${id}`, partialRaffle);
  }

  delete(id: string) {
    return this.DELETE<{ response: number, message: string, id: string }>(`${API.ENPOINTS.raffle.delete}/?id=${id}`);
  }

  approveRaffle(id: string) {
    return this.POST<{ response: number, message: string }>(`${API.ENPOINTS.admin.approveRaffle}/${id}`);
  }

}

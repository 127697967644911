import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'padStart',
  standalone: true
})
export class PadStartPipe implements PipeTransform {

  transform(value: number, max: number): string {
    if (!value || !max) return '';
    return value.toString().padStart(`${max}`.length, '0');
  }

}

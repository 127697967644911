import { Pipe, PipeTransform } from '@angular/core';
import { RaffleStatus, StatusRaffleOptionsObj, TotalCardTotal } from '../models';

@Pipe({
  name: 'raffleStatusMap',
  standalone: true
})
export class RaffleStatusMapPipe implements PipeTransform {

  transform(value: TotalCardTotal): string {
    if (!value) return '';
    const keysRaffleStatus = Object.keys(StatusRaffleOptionsObj);
    if (!keysRaffleStatus.includes(`${value}`)) return '';
    return StatusRaffleOptionsObj[value as RaffleStatus];
  }

}

import { Component, Input } from '@angular/core';
import { IonSpinner, SpinnerTypes, IonText } from "@ionic/angular/standalone";
import { BaseClass } from '../../utils';

@Component({
  selector: 'operation-in-progress',
  templateUrl: './operation-in-progress.component.html',
  styleUrls: ['./operation-in-progress.component.scss'],
  standalone: true,
  imports: [IonText, IonSpinner,],
})
export class OperationInProgressComponent extends BaseClass() {

  @Input() spinner: SpinnerTypes = 'bubbles';
  @Input() message: string = 'Carregando...';

}

import { Routes } from '@angular/router';
import { HomeComponent } from './home/home.component';
// import { LoginComponent } from './login/login.component';
import { DashboardComponent } from './home/dashboard/dashboard.component';
import { RafflesComponent } from './home/raffles/raffles.component';
import { BookiesComponent } from './home/bookies/bookies.component';
import { RaffleDetailsComponent } from './home/raffles/raffle-details/raffle-details.component';
import { BookieDetailsComponent } from './home/bookies/bookie-details/bookie-details.component';
import { ChangePasswordComponent, authGuard, matchUrlChangePassword } from '@common/guards';
import { APP_NAME } from '@common/globals';
import { LoginComponentCommon, NotFoundComponent, ForgotPasswordComponent } from '@common/components';
import { BookiesWrapperComponent } from './home/bookies/bookies-wrapper/bookies-wrapper.component';

export const routes: Routes = [
  { path: 'login', component: LoginComponentCommon, title: APP_NAME + ' - Login' },
  { path: 'forgot-password', component: ForgotPasswordComponent, title: APP_NAME + ' - Esqueci a Senha' },
  {
    path: 'home',
    component: HomeComponent,
    canActivate: [authGuard],
    title: APP_NAME + ' - Home',
    children: [
      { path: 'dashboard', component: DashboardComponent, title: APP_NAME + ' - Dashboard' },
      { path: 'raffles', component: RafflesComponent, title: APP_NAME + ' - Rifas' },
      { path: 'raffles/:id', component: RaffleDetailsComponent, title: APP_NAME + ' - Rifa | Detalhes' },
      {
        path: 'bookies',
        component: BookiesWrapperComponent,
        title: APP_NAME + ' - Rifeiros',
        children: [
          { path: 'list', component: BookiesComponent, title: APP_NAME + ' - Rifeiros' },
          { path: 'new', component: BookieDetailsComponent, title: APP_NAME + ' - Rifeiro | Novo' },
          { path: ':id', component: BookieDetailsComponent, title: APP_NAME + ' - Rifeiro | Detalhes' },
          { path: '', redirectTo: 'list', pathMatch: 'full' },
          { path: '**', component: NotFoundComponent, title: APP_NAME + ' - Not Found' },
        ]
      },
      { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
      { path: '**', component: NotFoundComponent, title: APP_NAME + ' - Not Found' },
    ]
  },
  { path: '', redirectTo: 'home', pathMatch: 'full', },
  { matcher: matchUrlChangePassword, component: ChangePasswordComponent, title: APP_NAME + ' - Alterar Senha' }, // keep at the end to prevent unecessary checks
  { path: '**', component: NotFoundComponent, title: APP_NAME + ' - Not Found' },
];

import { Component } from '@angular/core';
import {
  IonContent,
  IonLabel,
  IonList,
  IonListHeader,
  IonNote,
  IonText,
  IonButton,
  IonAvatar,
} from "@ionic/angular/standalone";
import { BaseClass } from '../../utils';
import { APP_ID, FRASE_EFEITO, LOGO_URL } from '../../globals';
import { FormControl, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { RouterLink } from '@angular/router';
import { AlertService, AuthService, LoadingService, ToastService } from '../../services';
import { AsyncPipe } from '@angular/common';
import { NgxMaskDirective } from 'ngx-mask';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss'],
  standalone: true,
  imports: [IonAvatar, IonButton, IonText, IonNote, IonListHeader, IonList, IonLabel, IonContent, ReactiveFormsModule, MatFormFieldModule, MatInputModule, RouterLink, AsyncPipe, NgxMaskDirective],
})
export class ForgotPasswordComponent extends BaseClass() {

  appId = APP_ID;
  logoUrl = LOGO_URL;
  fraseEfeito = FRASE_EFEITO;

  emailControl: FormControl<string> = new FormControl('', [Validators.required, Validators.email]);
  cpfControl: FormControl<string> = new FormControl('', [Validators.required, Validators.minLength(11), Validators.maxLength(11)]);

  constructor(
    private authService: AuthService,
    private alertService: AlertService,
    private loadingService: LoadingService,
    private toastService: ToastService,
  ) {
    super();
  }

  async send() {
    if (this.appId.value === 'admin' && this.emailControl.invalid) {
      this.toastService.present({ message: 'E-mail inválido', duration: 3000 });
      return;
    }

    if (this.appId.value !== 'admin' && this.cpfControl.invalid) {
      this.toastService.present({ message: 'CPF inválido', duration: 3000 });
      return;
    }

    const confirm = await this.alertService.confirm("Confirmação", `Deseja solicitar a recuperação de senha para o ${this.appId.value === 'admin' ? 'e-mail' : 'CPF'} informado?`);
    if (!confirm) return;

    const loading = await this.loadingService.presentLoading();

    try {
      const email = this.emailControl.value;
      const cpf = this.cpfControl.value;

      let obj = {};
      if (this.appId.value === 'admin') obj = { email };
      else obj = { cpf };

      this.cpfControl.disable();
      this.emailControl.disable();

      const res = await this.authService.requestPasswordReset(this.appId.value, { email: this.emailControl.value, cpf: this.cpfControl.value });
      if (res.response === 201) {
        this.alertService.presentAlert("Email enviado", "Um link para recuperação de senha foi enviado para o seu endereço de e-mail cadastrado", "success");
      } else {
        this.toastService.present({ message: 'Erro ao solicitar recuperação de senha, tente novamente', color: 'danger' });
      }
    } catch (err) {
      console.log(err);
      if ((err as any)?.['error']?.response === 404) {
        this.alertService.presentAlert("Usuário não encontrado", `Nenhum usuário encontrado com o ${this.appId.value === 'admin' ? 'e-mail' : 'CPF'} informado`, "danger");
      } else {
        this.toastService.present({ message: 'Erro ao solicitar recuperação de senha', color: 'danger' });
      }
    } finally {
      loading.dismiss();
      this.cpfControl.enable();
      this.emailControl.enable();
    }
  }

}

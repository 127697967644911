import { Injectable } from '@angular/core';
import { LoadingController } from '@ionic/angular/standalone';

@Injectable({
  providedIn: 'root'
})
export class LoadingService {

  constructor(private loadinController: LoadingController) {}

  async presentLoading(message: string = 'Carregando...'): Promise<HTMLIonLoadingElement> {
    const loading = await this.loadinController.create({
      message,
      spinner: 'bubbles',
      translucent: true,
      keyboardClose: false,
      showBackdrop: true,
    });
    await loading.present();
    return loading;
  }
}

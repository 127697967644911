import { Injectable } from '@angular/core';
import { ToastController, ToastButton } from '@ionic/angular';
import { Color } from '@ionic/core';

@Injectable({
  providedIn: 'root'
})
export class ToastService {

  constructor(private toastController: ToastController,) { }

  async present(config: { message: string, color?: Color, duration?: number, showDismissButton?: boolean, cssClass?: string | Array<string> }) {
    if (!config.color) config.color = 'primary';
    if (!config.duration) config.duration = 3000;
    if (!config.showDismissButton) config.showDismissButton = true;
    if (!config.cssClass) config.cssClass = ['align-end'];

    const buttons: Array<ToastButton> = [];

    const { showDismissButton, ...opts } = config;

    if (showDismissButton) buttons.push({ text: 'Ok', role: 'cancel' });

    const toast = await this.toastController.create({
      ...opts,
      buttons,
    });
    await toast.present();
    return toast;
  }
}

import { Injectable } from '@angular/core';
import { Bookie, Bookies } from '../models/bookie.model';
import { API } from '../api';
import { BaseHttpService } from './base-http.service';

@Injectable({
  providedIn: 'root',
})
export class BookieService extends BaseHttpService {
  getBookies() {
    return this.GET<{ message: string; creators: Bookies }>(API.ENPOINTS.globals.allCreators);
  }

  getBookie(id: string) {
    return this.GET<{ message: string; bookie: Bookie }>(API.ENPOINTS.bookie.get + id);
  }

  getCountBookies() {
    return this.GET<{ message: string; count: number }>(API.ENPOINTS.bookie.count);
  }

  getGrossRevenue(id: string) {
    return this.GET<{ message: string; total: number }>(`${API.ENPOINTS.bookie.grossRevenue}/${id}`);
  }

  addBookie(bookie: Bookie) {
    return this.POST<{ bookie: Bookie }>(API.ENPOINTS.bookie.create, bookie);
  }

  updateBookie(id: string, bookie: Partial<Bookie>) {
    return this.PUT<{ response?: number, message: string }>(`${API.ENPOINTS.bookie.update}/?id=${id}`, bookie);
  }

  deleteBookie(id: number) {
    return this.DELETE(`${API.ENPOINTS.bookie.delete}?id=${id}`);
  }

  finishRaffle(idRaffle: string, drawnNumber: number) {
    return this.PUT<{ response: number, message: void }>(`${API.ENPOINTS.bookie.finishRaffle}/${idRaffle}/${drawnNumber}`);
  }
}

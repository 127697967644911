import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { IonContent, IonSegment, IonSegmentButton, IonLabel, IonSkeletonText } from "@ionic/angular/standalone";
import { FormsModule } from '@angular/forms';
import { SectionDetailsRaffleComponent } from './section-details-raffle/section-details-raffle.component';
import { BaseClass, navigateReplace } from '@common/globals';
import { Raffle } from '@common/models';
import { RaffleService, ToastService } from '@common/services';
import { Breadcrumb, BreadcrumbsComponent } from '@common/components';
import { SectionDetailsRaffleSkeletonComponent } from './section-details-raffle-skeleton/section-details-raffle-skeleton.component';

@Component({
  selector: 'app-raffle-details',
  templateUrl: './raffle-details.component.html',
  styleUrls: ['./raffle-details.component.scss'],
  standalone: true,
  imports: [IonSkeletonText, IonLabel, IonSegmentButton, IonSegment, IonContent, BreadcrumbsComponent, FormsModule, SectionDetailsRaffleComponent, SectionDetailsRaffleSkeletonComponent],
})
export class RaffleDetailsComponent extends BaseClass() implements OnInit {

  breadcrumbs: Array<Breadcrumb> = [
    { link: '..', text: 'Rifas' },
    { link: null, text: 'Detalhes Rifa' },
  ];

  activeSegment: SegmentValue = 'details';

  segments: Array<Segment> = [
    { value: 'details', label: 'Detalhes' },
  ];

  raffle: Raffle;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private toastService: ToastService,
    private raffleService: RaffleService,
  ) {
    super();
  }

  ngOnInit() {
    const raffleId = this.route?.snapshot?.paramMap?.get('id');
    if (raffleId) {
      this.getRaffleDetails(raffleId);
    } else {
      this.navigateRaffles('Rifa não encontrada');
    }
  }

  getRaffleDetails(id: string) {
    this.raffleService.getRaffleById(id).then((res) => {
      this.raffle = res;
    }).catch((err) => {
      console.log(err);
      this.navigateRaffles('Não foi possível carregar os detalhes da rifa');
    });
  }

  private navigateRaffles(message: string = null) {
    if (message) this.toastService.present({ message, color: 'danger' });
    navigateReplace(this.router, ['/', 'home', 'raffles']);
  }

  segmentChanged() {

  }

}

type Segment = {
  value: SegmentValue;
  label: string;
};

type SegmentValue = 'details';

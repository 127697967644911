import { Component, OnInit } from '@angular/core';
import { RouterOutlet } from '@angular/router';

@Component({
  selector: 'app-bookies-wrapper',
  templateUrl: './bookies-wrapper.component.html',
  standalone: true,
  imports: [RouterOutlet],
})
export class BookiesWrapperComponent implements OnInit {

  constructor() {}

  ngOnInit() {}

}

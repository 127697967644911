import { Component, Input, OnInit } from '@angular/core';
import { IonButton, IonIcon } from "@ionic/angular/standalone";
import { Bookie } from '../../models';
import { addIcons } from 'ionicons';
import { logoFacebook, logoInstagram, logoTiktok, logoTwitter } from 'ionicons/icons';

addIcons({ logoFacebook, logoInstagram, logoTiktok, logoTwitter });

@Component({
  standalone: true,
  imports: [IonIcon, IonButton, IonIcon],
  selector: 'social',
  templateUrl: './social.component.html',
  styleUrls: ['./social.component.scss'],
})
export class SocialComponent implements OnInit {

  @Input() bookie: Bookie;

  constructor() {}

  ngOnInit() {}

}

<ion-list id="form" lines="none" mode="md" class="margin-md skeleton">
  <ion-avatar id="avatar">
    <ion-skeleton-text animated />
  </ion-avatar>

  <div id="cards">
    <card-total-skeleton [arraySize]="5" />
  </div>

  @for (i of formFields; track $index) {
    <mat-form-field appearance="outline" class="skeleton" floatLabel="always">
      <ion-skeleton-text animated />
      <mat-label> <ion-skeleton-text animated class="in-label" /> </mat-label>
      <input matInput />
    </mat-form-field>
  }
</ion-list>

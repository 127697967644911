<div id="cards">
  @for (i of cards; track $index) {
    <ion-card mode="md">
      <ion-card-header>
        <h6 class="text-ellipsis value">
          <ion-skeleton-text animated />
        </h6>
        <h5 class="text-ellipsis label">
          <ion-skeleton-text animated />
        </h5>
      </ion-card-header>
    </ion-card>
  }
</div>

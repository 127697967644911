import { BehaviorSubject } from 'rxjs';
import { AppID } from '../api';

export const APP_VERSION = '1.1.2';

export const RECAPTCHA_SITE_KEY = '6LdDOropAAAAAOv-7vhptYABD7aMPVqxYn4dcMBw';

export const APP_NAME = 'Portal da Sorte';
export const LOGO_URL = 'https://firebasestorage.googleapis.com/v0/b/portal-da-sorte-3333.appspot.com/o/logo.png?alt=media&token=46bc03a7-a656-4486-a16b-c7e0d36c98db';
export const FRASE_EFEITO = 'O seu portal de sorte';

export const ENABLE_TRANSPARENCY = true;

export const title$: BehaviorSubject<string> = new BehaviorSubject<string>('');

export const MOBILE_BREAKPOINT = 768;

export const BUTTON_HEIGHT = 44;

export const SPACING_SM = 8;
export const SPACING_MD = 16;
export const SPACING_LG = 32;
export const SPACING_XL = 36;

export const BORDER_RADIUS = 8;

export const ANIMATION_DURATION = 300;
export const MENU_WIDTH = 220;

export const DEFAULT_SIZE_MAT_PAGINATOR = [10, 20, 30, 50, 100];

export const DEFAULT_RAFFLE_SIZE = 100000;

export const MAX_TIME_AWAY = 1000 * 60 * 60 * 24 * 7; // 7 days

export const MAX_IMAGES_RAFFLE = 3;

export const MIN_PASSWORD_LENGTH = 6;

export const MIN_USERNAME_LENGTH = 3;

export const MAX_IMAGE_SIZE = 15 * 1048576; // 5MB

export const MIN_CHARACTERS_USERNAME = 3;

export const MIN_CHARACTERS_PASSWORD = 6;

export const APP_ID: BehaviorSubject<AppID> = new BehaviorSubject<AppID>(null);

export const SLIDE_SPEED = 5000;

export const RAFFLES_CONFIG = {
  MIN_NUMBERS: 1,
  MAX_NUMBERS_CREATE: 10000000,
  MAX_NUMBERS_START: 2147483647 - 10000000,
  MAX_CURRENCY: 500,
  AMOUNT_BUTTON: 10,
  MIN_QUOTA_VALUE: .01,
}

export const TAXA_MERCADO_PAGO = .0099;

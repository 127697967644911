import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'abrNumber',
  standalone: true
})
export class AbrNumberPipe implements PipeTransform {

  transform(_value: number | string): string {
    if (_value == null || _value === '-' || isNaN(+_value)) return '-';
    const value = typeof _value === 'string' ? parseInt(_value) : _value;
    if (value < 1000) return value.toString();
    if (value < 1000000) return (value / 1000).toFixed(1) + 'K';
    if (value < 100000000) return (value / 1000000).toFixed(1) + 'M';
    return (value / 1000000000).toFixed(1) + 'B';
  }

}

<ion-content>
  <div id="container">
    <ion-list id="left">
      <div>
        <ion-avatar class="margin-bottom-lg">
          <img [src]="logoUrl" />
        </ion-avatar>

        @if (!isMobile) {
          <h1 class="linear-g-color">Portal da Sorte</h1>

          <p>{{ fraseEfeito }}</p>
        }
      </div>
    </ion-list>

    <ion-list id="right">
      <div>
        <section id="header">
          <h1>Esqueci minha senha</h1>
          <ion-text color="medium" class="italic">Digite seu {{ (appId | async) === "admin" ? "email" : "CPF" }} para recuperar sua senha</ion-text>
        </section>

        <section id="form">
          <mat-form-field id="login" appearance="outline">
            <input matInput [placeholder]="(appId | async) === 'admin' ? 'Email' : 'CPF'" [formControl]="(appId | async) === 'admin' ? emailControl : cpfControl" [mask]="(appId | async) === 'admin' ? null : '000.000.000-00'" />
          </mat-form-field>

          <ion-button fill="solid" (click)="send()">Enviar</ion-button>
        </section>

        <section class="flex space-between">
          <a [routerLink]="['/login']"> Voltar para o login </a>
        </section>
      </div>
    </ion-list>

    @if (!isMobile) {
      <hr />
    }
  </div>
</ion-content>

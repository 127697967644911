import { Injectable } from '@angular/core';
import { BaseHttpService } from './base-http.service';
import { API, Pagination } from '../api';
import { Transaction, TransactionExpanded } from '../models/transaction.model';

@Injectable({
  providedIn: 'root'
})
export class TransactionService extends BaseHttpService {

  getTransactionById(id: string) {
    return this.GET<{ transaction: Transaction, response: number }>(`${API.ENPOINTS.transaction.getById}/${id}`);
  }

  getTransactionByIdMP(id: string) {
    return this.GET<{ transaction: Transaction, response: number }>(`${API.ENPOINTS.transaction.getByIdMP}/${id}`);
  }

  getTransactionsClient(id: string, page: number = 1) {
    return this.GET<{ response: number, purchasedRaffles: { transactions: Array<TransactionExpanded> } & Pagination }>(`${API.ENPOINTS.client.purchasedRaffles}/${id}/${page}`);
  }

}

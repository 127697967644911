<ion-list lines="none" mode="md" class="details-raffle-form margin-md">
  <div id="cards">
    <card-total-skeleton [arraySize]="6" />
  </div>

  <mat-form-field id="name" appearance="outline" class="skeleton" floatLabel="always">
    <label> Nome </label>
    <ion-skeleton-text animated />
    <mat-label> <ion-skeleton-text animated class="in-label" /> </mat-label>
    <input matInput />
  </mat-form-field>

  <mat-form-field id="award" appearance="outline" class="skeleton" floatLabel="always">
    <label> Prêmio </label>
    <ion-skeleton-text animated />
    <mat-label> <ion-skeleton-text animated class="in-label" /> </mat-label>
    <input matInput />
  </mat-form-field>

  <mat-form-field id="bookie" appearance="outline" class="skeleton" floatLabel="always">
    <label> Rifeiro </label>
    <ion-skeleton-text animated />
    <mat-label> <ion-skeleton-text animated class="in-label" /> </mat-label>
    <input matInput />
  </mat-form-field>

  <mat-form-field id="description" appearance="outline" class="skeleton" floatLabel="always">
    <label> Descrição </label>
    <ion-skeleton-text animated />
    <mat-label> <ion-skeleton-text animated class="in-label" /> </mat-label>
    <textarea matInput [rows]="5"></textarea>
  </mat-form-field>

  <mat-form-field id="create_at" appearance="outline" class="skeleton" floatLabel="always">
    <label> Criado em </label>
    <ion-skeleton-text animated />
    <mat-label> <ion-skeleton-text animated class="in-label" /> </mat-label>
    <input matInput />
  </mat-form-field>

  <mat-form-field id="draw_prediction" appearance="outline" class="skeleton" floatLabel="always">
    <label> Previsão Sorteio </label>
    <ion-skeleton-text animated />
    <mat-label> <ion-skeleton-text animated class="in-label" /> </mat-label>
    <input matInput />
  </mat-form-field>

  <mat-form-field id="draw_date" appearance="outline" class="skeleton" floatLabel="always">
    <label> Sorteio </label>
    <ion-skeleton-text animated />
    <mat-label> <ion-skeleton-text animated class="in-label" /> </mat-label>
    <input matInput />
  </mat-form-field>
</ion-list>

import { Component } from '@angular/core';
import { IonContent, IonLabel, IonItem, IonSearchbar, IonButton, IonIcon, IonButtons, IonInput, IonText, IonFab, IonFabButton } from "@ionic/angular/standalone";
import { MatTableModule } from "@angular/material/table";
import { MatPaginatorModule } from '@angular/material/paginator';
import { KeyValuePipe } from '@angular/common';
import { BookiesListComponent } from './bookies-list/bookies-list.component';
import { addIcons } from 'ionicons';
import { search, add } from 'ionicons/icons';
import { BookieAddComponent } from './bookie-add/bookie-add.component';
import { RouterLink } from '@angular/router';
import { BaseClass } from '@common';
import { Bookies } from '@common';
import { BookieService, ModalService } from '@common';

addIcons({ search, add });

const IONIC_COMPONENTS = [IonFabButton, IonFab, IonText, IonInput, IonButtons, IonIcon, IonButton, IonSearchbar, IonItem, IonLabel, IonContent];

@Component({
  selector: 'app-bookies',
  templateUrl: './bookies.component.html',
  styleUrls: ['./bookies.component.scss'],
  standalone: true,
  imports: [IONIC_COMPONENTS, MatTableModule, MatPaginatorModule, KeyValuePipe, BookiesListComponent, RouterLink],
})
export class BookiesComponent extends BaseClass() {

  bookies: Bookies;

  constructor(
    private overlayService: ModalService,
    private bookieService: BookieService,
  ) {
    super();
  }

  ngOnInit() {
    this.getBookies();
  }

  // async insert() {
  //   const bookies: Bookies = BOOKIES;
  //   for (const bookie of bookies) {
  //     bookie.password = hashPassword(bookie.password);
  //     const res = await this.bookieService.addBookie(bookie);
  //     console.log('insert', res);
  //   }
  // }

  async getBookies() {
    this.bookieService.getBookies().then((res) => {
      this.bookies = res.creators;
    }).catch((err) => {
      console.error('getBookies', err);
    });
  }

  search(key: string) {
    if (!key) {
      this.getBookies();
      return;
    }

    // this.bookieService.search(key).then((res) => {
    //   this.bookies = res.data;
    // }).catch((err) => {
    //   console.error('search', err);
    // });
  }

  async openAddBookieModal() {
    const modal = await this.overlayService.presentModal(BookieAddComponent);
  }
}

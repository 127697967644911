import { Component, OnInit } from '@angular/core';
import { IonIcon, IonContent, IonLabel, IonListHeader } from '@ionic/angular/standalone';
import { addIcons } from 'ionicons';
import { ticket, people, cash } from 'ionicons/icons';
import { AdminService, BaseClass, CardTotalComponent, RafflesListComponent, TotalCard } from '@common';
import { Raffles } from '@common';
import { RaffleService } from '@common/services';

addIcons({ ticket, people, cash });

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  standalone: true,
  imports: [IonListHeader, IonLabel, IonContent, IonIcon, CardTotalComponent, RafflesListComponent],
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent extends BaseClass() implements OnInit {

  cards: Array<TotalCard> = [];

  raffles: Raffles;

  constructor(
    private raffleService: RaffleService,
    private adminService: AdminService,
  ) {
    super();
  }

  ngOnInit() {
    this.getRaffles();
  }

  async setCards() {
    const res = await Promise.all([
      this.getCountRaffles(),
      this.getCountBookies(),
      this.getAllTimeGrossRevenue(),
    ]);

    this.cards = [
      { label: 'Rifas - Todas', icon: 'ticket', total: isNaN(res?.[0]) ? '-' : res?.[0], type: isNaN(res?.[0]) ? null : 'number', path: ['/home', 'raffles'] },
      { label: 'Total Rifeiros', icon: 'people', total: isNaN(res?.[1]) ? '-' : res?.[1], type: isNaN(res?.[1]) ? null : 'number', path: ['/home', 'bookies'] },
      { label: 'Total Movimentado (R$)', icon: 'cash', total: isNaN(res?.[2]) ? '-' : res?.[2], type: isNaN(res?.[2]) ? null : 'currency', path: ['/home', 'raffles'] },
      { label: 'Média arrecado por rifa', icon: 'cash', total: isNaN(res?.[2]) || isNaN(res?.[0]) ? '-' : res?.[2] / res?.[0], type: isNaN(res?.[2]) || isNaN(res?.[0]) ? null : 'currency' },
    ];
  }

  getRaffles() {
    this.raffleService.getRaffles().then((res) => {
      this.raffles = res.raffles;
      this.setCards();
    }).catch((err) => {
      console.error('getRaffles', err);
    });
  }

  async getCountBookies(): Promise<number> {
    return (await this.adminService.getCountBookies())?.count || null
  }

  async getCountRaffles(onlyActive: boolean = false): Promise<number> {
    return (await this.raffleService.getCountRaffles(null, onlyActive ? 'ACTIVE' : null))?.count || null;
  }

  async getAllTimeGrossRevenue(): Promise<number> {
    return (await this.adminService.getAllTimeGrossRevenue())?.totalSelling || null;
  }

}

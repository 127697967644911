<ion-content>
  <breadcrumbs [breadcrumbs]="breadcrumbs" />

  <ion-segment [(ngModel)]="activeSegment" mode="md" (ionChange)="segmentChanged()">
    @for (segment of segments; track $index) {
      <ion-segment-button [value]="segment.value">
        <ion-label> {{ segment.label }} </ion-label>
      </ion-segment-button>
    }
  </ion-segment>

  <div id="content">
    @switch (activeSegment) {
      @case ("details") {
        @if (raffle) {
          <section-details-raffle [raffle]="raffle" />
        } @else {
          <section-details-raffle-skeleton />
        }
      }

      @case ("buyers") {}
    }
  </div>
</ion-content>

<ion-list mode="md" class="table-list" [class]="app">
  @if (isDesktop) {
    <div class="header row border-top-radius-md">
      <ion-text class="create-at"> Criada </ion-text>
      <ion-text class="draw-date"> Sorteio </ion-text>
      @if (app !== "bookie") {
        <ion-text class="bookie"> Rifeiro </ion-text>
      }
      <ion-text class="title"> Descrição </ion-text>
      <ion-text class="numbers"> Números </ion-text>
      @if (app === "bookie" && isDesktop) {
        <ion-text class="available"> Disponível </ion-text>
      }
      <ion-text class="ticket-value ion-text-center"> Ticket </ion-text>
      <ion-text class="progress ion-text-center"> Progresso </ion-text>
      <ion-text class="status ion-text-center"> Status </ion-text>
    </div>
  }

  <div class="body">
    @if (!raffles) {
      @for (_i of [1, 2, 3]; track $index) {
        <div class="row ion-text-center skeleton">
          <ion-text class="draw-date"><ion-skeleton-text animated /></ion-text>
          <ion-text class="status"><ion-skeleton-text animated /></ion-text>
          <ion-text class="title"><ion-skeleton-text animated /></ion-text>
          <ion-text class="ticket-value"><ion-skeleton-text animated /></ion-text>
          @if (app !== "bookie") {
            <ion-text class="bookie"><ion-skeleton-text animated /></ion-text>
          }
          <ion-text class="create-at"><ion-skeleton-text animated /></ion-text>
          <ion-text class="numbers"><ion-skeleton-text animated /></ion-text>
          @if (app === "bookie" && isDesktop) {
            <ion-text class="available"><ion-skeleton-text animated /></ion-text>
          }
          <ion-text class="progress"><ion-skeleton-text animated /></ion-text>
        </div>
      }
    }

    @for (raffle of raffles; track $index) {
      <div class="row hover-pointer" [class]="raffle.status" (click)="goToRaffle(raffle.id)">
        <ion-text class="create-at"> {{ raffle.create_at | date: "dd/MM/yyyy" }} </ion-text>
        <ion-text class="draw-date flex">
          @if (!isDesktop) {
            <span> Sorteio: </span>
          }
          <span> {{ raffle.draw_date || raffle.draw_prediction | date: "dd/MM/yyyy" }} </span>
        </ion-text>
        @if (app !== "bookie") {
          <ion-text class="bookie"> {{ raffle.bookie?.name | titlecase }} </ion-text>
        }
        <ion-text class="title">
          <ion-text> {{ raffle.name }} </ion-text>
          @if (isDesktop) {
            <ion-note class="italic small"> {{ raffle.award }} </ion-note>
          }
        </ion-text>
        <ion-text class="numbers"> {{ raffle.amount_numbers | abrNumber }} </ion-text>
        @if (app === "bookie" && isDesktop) {
          <ion-text class="available"> {{ raffle.amount_numbers - raffle.total_sellings | abrNumber }} </ion-text>
        }
        <ion-text class="ticket-value"> {{ raffle.quota_value | currency: "R$ " }} </ion-text>
        <ion-text class="progress flex-column align-center justify-center">
          @if (isDesktop) {
            <span> {{ raffle.total_sellings / raffle.amount_numbers | percent: "0.2-2" }} </span>
          }
          <ion-progress-bar [ariaCurrent]="raffle.total_sellings / raffle.amount_numbers | percent: '0.2-2'" [value]="raffle.total_sellings / raffle.amount_numbers" color="secondary" />
        </ion-text>
        <ion-text class="status">
          <ion-badge [color]="raffleStatusColorMap[raffle.status]"> {{ raffle.status | raffleStatusMap }} </ion-badge>
        </ion-text>
      </div>
    }

    @if (raffles?.length === 0) {
      <div class="footer row ion-text-center padding-block-sm">
        <ion-text>
          <ion-note> Nenhuma rifa encontrada </ion-note>
        </ion-text>
      </div>
    }

    @if (showAllButton) {
      <div class="footer row hover-pointer ion-text-center padding-block-sm" [routerLink]="['/home', 'raffles']">
        <ion-text>
          <ion-note class="linear-g-color margin-inline-auto"> VER TODAS </ion-note>
        </ion-text>
      </div>
    }
  </div>
</ion-list>

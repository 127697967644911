import { Component, Input, OnInit } from '@angular/core';
import { RouterLink } from '@angular/router';
import { IonBreadcrumb, IonBreadcrumbs, IonIcon } from "@ionic/angular/standalone";

@Component({
  selector: 'breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
  styleUrls: ['./breadcrumbs.component.scss'],
  standalone: true,
  imports: [IonIcon, IonBreadcrumbs, IonBreadcrumb, RouterLink]
})
export class BreadcrumbsComponent implements OnInit {

  @Input() breadcrumbs: Array<Breadcrumb> = []

  constructor() { }

  ngOnInit() { }

}

export declare type Breadcrumb = {
  text: string;
  link: string;
}

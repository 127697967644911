import { Component } from '@angular/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { IonList, IonSkeletonText } from "@ionic/angular/standalone";
import { BaseClass, CardTotalSkeletonComponent } from '@common/components';

@Component({
  selector: 'section-details-raffle-skeleton',
  templateUrl: './section-details-raffle-skeleton.component.html',
  styleUrls: ['../raffle-details.component.scss'],
  standalone: true,
  imports: [IonSkeletonText, IonList, CardTotalSkeletonComponent, MatFormFieldModule, MatInputModule],
})
export class SectionDetailsRaffleSkeletonComponent extends BaseClass() {
}

import { Component } from '@angular/core';
import { ActivatedRoute, Router, RouterLink, RouterLinkActive } from '@angular/router';
import { IonList, IonIcon, IonMenuToggle, IonLabel, IonItem, IonContent } from '@ionic/angular/standalone';
import { AuthService } from '@common';
import { BaseClass } from '@common';
import { addIcons } from 'ionicons';
import { home, homeOutline, ticket, ticketOutline, people, peopleOutline, settings, personAdd, personAddOutline, settingsOutline, logOut, logOutOutline } from 'ionicons/icons';

addIcons({ home, homeOutline, ticket, ticketOutline, people, peopleOutline, settings, personAdd, personAddOutline, settingsOutline, logOut, logOutOutline })

@Component({
  selector: 'sidenav',
  templateUrl: './sidenav.component.html',
  standalone: true,
  imports: [IonContent, IonItem, RouterLink, RouterLinkActive, IonList, IonIcon, IonMenuToggle, IonLabel],
  styleUrls: ['./sidenav.component.scss'],
})
export class SidenavComponent extends BaseClass() {

  constructor(
    private authService: AuthService,
    protected router: Router,
  ) {
    super();
  }

  pages: Array<AppPage> = [
    { title: 'Dashboard', icon: 'home', path: ['/home', 'dashboard'] },
    { title: 'Rifas', icon: 'ticket', path: ['/home', 'raffles'] },
    { title: 'Rifeiros', icon: 'people', path: ['/home', 'bookies'] },
  ];

  logout() {
    this.authService.logout();
    this.router.navigate(['/login'], { replaceUrl: true });
  }

}

export type AppPage = {
  title: string;
  icon: string;
  path: Array<string>;
}

import { Component, inject } from "@angular/core";
import { LoadingService, PaymentService, ToastService } from "../services";
import { FormGroup } from "@angular/forms";
import { Bookie } from "../models";
import { copyClipboard } from "./utils";
import { BaseClass } from "./base-class";

interface I_ConnectMercadoPago {
  loadingService: LoadingService;
  paymentService: PaymentService;
  toastService: ToastService;

  connectMercadoPago(form: FormGroup, bookie: Bookie): void;
  copyURLConnectMP(bookie: Bookie): void;
}

@Component({
  selector: 'connect-mercado-pago',
  template: ``,
})
export class ConnectMercadoPago extends BaseClass() implements I_ConnectMercadoPago {
  loadingService: LoadingService = inject(LoadingService);
  paymentService: PaymentService = inject(PaymentService);
  toastService: ToastService = inject(ToastService);

  async connectMercadoPago(form: FormGroup, bookie: Bookie) {
    const loading = await this.loadingService.presentLoading("Conectando com Mercado Pago... Aguarde");

    this.paymentService.connectURLMP(bookie.id).then((res) => {
      console.log(res);
      this.toastService.present({ message: 'URL de conexão com Mercado Pago gerada com sucesso', color: 'success' });
      form.patchValue({ url_connect_mp: res.url });
      bookie.url_connect_mp = res.url;
    }).catch((err) => {
      console.error('connectMercadoPago', err);
      let message = 'Erro ao gerar URL de conexão com Mercado Pago';
      if (err.error?.message) message = err.error.message;
      this.toastService.present({ message, color: 'danger' });
    }).finally(() => loading.dismiss());
  }

  async copyURLConnectMP(bookie: Bookie) {
    const copied = await copyClipboard(bookie.url_connect_mp);
    if (copied) return this.toastService.present({ message: 'URL copiada', color: 'success' });
    this.toastService.present({ message: 'Erro ao copiar URL', color: 'danger' });
  }
}

import { inject } from '@angular/core';
import { CanActivateChildFn, Router, UrlSegment, UrlMatchResult } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { APP_ID, MAX_TIME_AWAY } from '../globals';

export const authGuard: CanActivateChildFn = async (_, snap) => {
  const authService = inject(AuthService);
  const router = inject(Router);

  try {
    const session = authService.getSession();

    if (APP_ID.value === 'client' && snap?.url?.includes('/home/promotion/')) return true;

    const now = Date.now();
    if (!session?.timestamp || now - session.timestamp > MAX_TIME_AWAY) {
      const res = await authService.refreshToken();
      console.log(res, APP_ID.value, _, snap)
      if (!res.token && APP_ID.value !== 'client') {
        authService.logout();
        return goLogin(router);
      }
      authService.setSession({ ...session, token: res.token, timestamp: now });
    }

    if (!session) return goLogin(router);
    return true;
  } catch (error) {
    return goLogin(router);
  }
}

const goLogin = (router: Router) => {
  router.navigate(['/login']);
  return false;
}

export const matchUrlChangePassword = (url: UrlSegment[]): UrlMatchResult => {
  const posParams: { [key: string]: UrlSegment } = {};
  if (url?.[0]?.path === 'recover' && url?.[1]?.path === 'password') {
    posParams['recoverCode'] = new UrlSegment(url.slice(2).map(s => s.path).join('/'), {});
    return { consumed: url, posParams };
  }
  return null;
}

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { session$ } from '../api';

@Injectable({
  providedIn: 'root'
})
export class BaseHttpService {

  private token = session$;

  constructor(private httpClient: HttpClient) {}

  protected GET<T>(url: string, _params: Record<string, any> = {}) {
    const headers = this.setHeaders();
    const params = Object.fromEntries(Object.entries(_params).filter((x) => !!x[1]));
    return lastValueFrom(this.httpClient.get<T>(url, { headers, params }));
  }

  protected GET_NOAUTH<T>(url: string, _params: Record<string, any> = {}) {
    const params = Object.fromEntries(Object.entries(_params).filter((x) => !!x[1]));
    return lastValueFrom(this.httpClient.get<T>(url, { params }));
  }

  protected POST<T>(url: string, body: Record<string, any> = {}) {
    const headers = this.setHeaders();
    return lastValueFrom(this.httpClient.post<T>(url, body, { headers }));
  }

  protected POST_NOAUTH<T>(url: string, body: Record<string, any> = {}) {
    return lastValueFrom(this.httpClient.post<T>(url, body));
  }

  protected PUT<T>(url: string, body: Record<string, any> = {}) {
    const headers = this.setHeaders();
    return lastValueFrom(this.httpClient.put<T>(url, body, { headers }));
  }

  protected DELETE<T>(url: string, params: Record<string, any> = {}) {
    const headers = this.setHeaders();
    return lastValueFrom(this.httpClient.delete<T>(url, { headers, params }));
  }

  protected PATCH<T>(url: string, body: Record<string, any> = {}) {
    const headers = this.setHeaders();
    return lastValueFrom(this.httpClient.patch<T>(url, body, { headers }));
  }

  private setHeaders(): HttpHeaders {
    return new HttpHeaders({ 'Authorization': `Bearer ${this.token.value.token}` })
  }
}

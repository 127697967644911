import { AfterContentChecked, Component, HostBinding, HostListener, OnDestroy } from '@angular/core';
import { MOBILE_BREAKPOINT } from "../globals";
import { Subscription } from 'rxjs';

export interface I_Responsive {
  isMobile: boolean;
  isDesktop: boolean;

  windowResized?: () => void;
}

export interface I_Subscription {
  /**
   * ## Array de subscriptions
   */
  subs: Array<Subscription>;
  /**
   * ## Adiciona uma Subscription ao array de subscriptions
   */
  appendSubscription: (sub: Subscription) => void;
}

type ClassConstructor<T> = {
  new(...args: any[]): T;
};
class EmptyClass {}

/**
 * ## Retorna uma classe que opcionalmente herda de uma classe passada por parâmetro, portanto se for preciso extender outra classe, basta passá-la como parâmetro
 * */
export const BaseClass = <T>(t: ClassConstructor<T> = EmptyClass as ClassConstructor<T>) => {
  /**
   * Embora a utilização de randomizers para componentes não seja recomendada na maioria dos casos,
   * aqui ela é utilizada para garantir que cada componente que herda de BaseClass tenha um id único
   * evitando 'Component ID generation collision'(https://angular.io/errors/NG0912), que tecnicamente
   * não é um erro mas pode gerar conflitos em produção e testes.
   * */

  const componentId = 'c-' + Math.random().toString(36).substring(2);

  @Component({
    selector: 'app-window-resize',
    template: '',
    host: { 'id': componentId }
  })
  class _BaseClass extends (t as any) implements I_Responsive, I_Subscription, AfterContentChecked, OnDestroy {

    @HostBinding('id') id = componentId;

    subs: Array<Subscription> = [];

    isMobile: boolean;
    isDesktop: boolean;

    @HostListener('window:resize')
    onResize = () => {
      this.setResponsiveClasses();
      if (this['windowResized']) this['windowResized']();
    }

    constructor() {
      super();
      const clienteWidth = document.body.clientWidth;
      this.isMobile = clienteWidth <= MOBILE_BREAKPOINT;
      this.isDesktop = clienteWidth > MOBILE_BREAKPOINT;
    }

    _isMobile = (): boolean => document.body.clientWidth <= MOBILE_BREAKPOINT;
    _isDesktop = (): boolean => document.body.clientWidth > MOBILE_BREAKPOINT;

    setResponsiveClasses() {
      this.isMobile = this._isMobile();
      this.isDesktop = this._isDesktop();
      document.body.classList.toggle('_ismobile', this.isMobile);
      document.body.classList.toggle('_isdesktop', this.isDesktop);
    }

    ngAfterContentChecked() {
      if (this['contentChecked']) this['contentChecked']();
      this.setResponsiveClasses();
      const component = document.querySelector(`#${componentId}`);
      if (!component) return console.error('Component not found', component);
      const componentFirstHTMLChild = component?.firstChild as HTMLElement;
      componentFirstHTMLChild?.classList?.toggle('_ismobile', this.isMobile);
      componentFirstHTMLChild?.classList?.toggle('_isdesktop', this.isDesktop);
    }

    appendSubscription(sub: Subscription) {
      this.subs.push(sub);
    }

    ngOnDestroy() {
      this.subs?.forEach(sub => sub?.unsubscribe());
    }
  }

  return _BaseClass;
}

<ion-card mode="md" [routerLink]="totalCard?.path || null" [class.center]="center">
  <ion-card-header>
    @switch (totalCard?.type) {
      @case ("currency") {
        <h6 class="text-ellipsis value">{{ totalCard.total | currency: "BRL" }}</h6>
      }
      @case ("percentage") {
        <h6 class="text-ellipsis value">{{ totalCard.total | percent }}</h6>
      }
      @case ("abr-number") {
        <h6 class="text-ellipsis value">{{ totalCard.total === "-" ? "-" : (totalCard.total | abrNumber) }}</h6>
      }
      @case ("fmt-number") {
        <h6 class="text-ellipsis value">{{ totalCard.total | number: "1.0-0" : "pt-BR" }}</h6>
      }
      @case ("raffle-status") {
        <h6 class="text-ellipsis value">{{ totalCard.total | raffleStatusMap }}</h6>
      }
      @default {
        <h6 class="text-ellipsis value">{{ totalCard.total }}</h6>
      }
    }
    <h5 class="text-ellipsis label">{{ totalCard.label }}</h5>
  </ion-card-header>
</ion-card>

<ion-content>
  <div id="container">
    <ion-list id="left">
      <div>
        <ion-avatar class="margin-bottom-lg">
          <img [src]="logoUrl" />
        </ion-avatar>

        @if (!isMobile) {
          <h1 class="linear-g-color">Portal da Sorte</h1>

          <p>{{ fraseEfeito }}</p>
        }
      </div>
    </ion-list>

    <ion-list id="right">
      <div>
        <section id="header">
          <h1>Criar nova senha</h1>
          <ion-text color="medium" class="italic">Digite seu {{ (appId | async) === "admin" ? "email" : "CPF" }} e a nova senha</ion-text>
        </section>


        <section id="form">
          <mat-form-field id="login" appearance="outline">
            <input matInput [placeholder]="(appId | async) === 'admin' ? 'Email' : 'CPF'" [formControl]="(appId | async) === 'admin' ? emailControl : cpfControl" [mask]="(appId | async) === 'admin' ? null : '000.000.000-00'" />
          </mat-form-field>

          <mat-form-field id="password" appearance="outline">
            <input matInput type="password" placeholder="Nova senha" [formControl]="passwordControl" />
          </mat-form-field>

          <mat-form-field id="password-confirm" appearance="outline">
            <input matInput type="password" placeholder="Confirme a nova senha" [formControl]="passwordConfirmControl" />
          </mat-form-field>

          <ion-button fill="solid" (click)="save()">Confirmar</ion-button>
        </section>
      </div>
    </ion-list>

    @if (!isMobile) {
      <hr />
    }
  </div>
</ion-content>

import { Injectable } from '@angular/core';
import {
  CollectionReference,
  DocumentReference,
  DocumentSnapshot,
  Firestore,
  collection,
  doc,
  getDocs,
  onSnapshot,
  query,
  where,
} from '@angular/fire/firestore';
import { Transaction } from '../models';

@Injectable({
  providedIn: 'root'
})
export class FirebaseTransactionsService {

  private collection: CollectionReference<Transaction>;

  constructor(private firestore: Firestore) {
    this.collection = collection(this.firestore, 'transactions') as CollectionReference<Transaction>;
  }

  getTransactionById(id: string, cb: (doc: DocumentSnapshot<Transaction, Transaction>) => void) {
    const docRef = doc(this.collection, id) as DocumentReference<Transaction, Transaction>;
    return onSnapshot(docRef, cb);
  }

  async getTransactionsClient(clientId: string): Promise<Array<Transaction>> {
    const q = query(this.collection, where('user_id', '==', clientId));
    const docsSnaps = await getDocs(q);
    return docsSnaps.docs.map(doc => ({ ...doc.data(), id: doc.id }));
  }

}

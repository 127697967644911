import { MatPaginator } from "@angular/material/paginator";
import { MatTableDataSource } from "@angular/material/table";
import { DEFAULT_SIZE_MAT_PAGINATOR, BUTTON_HEIGHT, DEFAULT_RAFFLE_SIZE } from "../globals";
import * as CryptoJS from 'crypto-js';
import { ActivatedRoute, Router } from "@angular/router";
import { Raffle, TRaffleForm } from "../models";
import { FormControl, FormGroup } from "@angular/forms";
import { HttpParams } from "@angular/common/http";
import { Location as NgLocation } from "@angular/common";
import { Clipboard as CapClipboard } from '@capacitor/clipboard';

export const setMatPaginatorSize = <T>(paginator: MatPaginator, availableSpace: number, dataSource: MatTableDataSource<T>) => {
  const pageSize = Math.floor(availableSpace / BUTTON_HEIGHT);
  paginator.pageSizeOptions = DEFAULT_SIZE_MAT_PAGINATOR;
  paginator.pageSize = Math.floor(DEFAULT_SIZE_MAT_PAGINATOR.filter(x => x <= pageSize).reverse()?.[0]);
  if (dataSource) dataSource.paginator = paginator;
}

export const randomNumber = (min: number, max: number) => {
  return Math.floor(Math.random() * (max - min + 1) + min);
}

export const cpfIsValid = (cpf: string) => {
  if (!cpf) return false;
  cpf = cpf.replace(/[^\d]+/g, '');
  if (cpf.length !== 11) return false;
  if ([...new Set(cpf.split(''))].length === 1) return false;
  let add = 0;
  for (let i = 0; i < 9; i++) add += parseInt(cpf.charAt(i)) * (10 - i);
  let rev = 11 - (add % 11);
  if (rev === 10 || rev === 11) rev = 0;
  if (rev !== parseInt(cpf.charAt(9))) return false;
  add = 0;
  for (let i = 0; i < 10; i++) add += parseInt(cpf.charAt(i)) * (11 - i);
  rev = 11 - (add % 11);
  if (rev === 10 || rev === 11) rev = 0;
  if (rev !== parseInt(cpf.charAt(10))) return false;
  return true;
}

export const hashPassword = (password: string) => {
  return CryptoJS.SHA256(password).toString();
}

export const navigateReplace = (router: Router, path: Array<string>) => {
  router.navigate(path, { replaceUrl: true });
}

export const navigateRelative = (opts: {
  router: Router,
  path: Array<string>,
  route: ActivatedRoute,
  replayUrl?: boolean,
}) => {
  const { router, path, route } = opts;
  router.navigate(path, { relativeTo: route, replaceUrl: opts.replayUrl || false });
}

export const getFormRaffle = (raffle: Partial<Raffle> = null) => {
  return new FormGroup<TRaffleForm>({
    amount_numbers: new FormControl(DEFAULT_RAFFLE_SIZE),
    award: new FormControl(raffle?.award || null),
    description_award: new FormControl(raffle?.description_award || null),
    draw_prediction: new FormControl(raffle?.draw_prediction || null),
    name: new FormControl(raffle?.name || null),
    quota_value: new FormControl(raffle?.quota_value?.toFixed(2) || null),
    start_at: new FormControl(raffle?.start_at || 1),
  });
}

export const inputOnCurrencyInput = (input: HTMLInputElement, _event: Event) => {
  const ev = _event as InputEvent;
  const key = ev.data;
  if (key === ',') return input.value = `${input.value}.`;
  // const inputValue = input.value.replace("R$ ", "");
  // if (inputValue.length > 1 && (inputValue.length > (+inputValue).toString().length)) input.value = `R$ ${+inputValue}`;
}

/**
 * Atualiza a url com os parâmetros fornecidos
 * @param _location deve ser importado de @angular/common
 * @param params parâmetros a serem adicionados na url
 */
export const updateUrl = (_location: NgLocation, params: Record<string, string | boolean | number> = {}) => {
  const _params = new HttpParams().appendAll(params);
  _location.replaceState(location.pathname, _params.toString());
}

export const copyClipboard = async (text: string): Promise<boolean> => {
  return CapClipboard.write({ string: text }).then(() => true).catch(async () => {
    try {
      await navigator.clipboard.writeText(text);
      return true;
    } catch {
      return false;
    }
  });
}

export const roundUp = (num: number, decimalPlaces: number) => {
  const p = Math.pow(10, decimalPlaces || 0);
  const n = (num * p) * (1 - Math.sign(num) * Number.EPSILON);
  return Math.ceil(n) / p;
}

export const roundDown = (num: number, decimalPlaces: number) => {
  const p = Math.pow(10, decimalPlaces || 0);
  const n = (num * p) * (1 + Math.sign(num) * Number.EPSILON);
  return Math.floor(n) / p;
}

import { Pagination } from "../api";
import { Bookie } from "./bookie.model";
import { Raffle } from "./raffle.model";
import { Client } from "./client.model";
import { Color } from '@ionic/core'

export type TransactionCreate = {
  id_user: string;
  id_bookie: string;
  id_raffle: string;
  amount: number;
  qtd_numbers: number;
  user_email: string;
  cpf_user: string;
  url_pagamento: string;
}

export type Transaction = {
  id?: string;
  user_id: string;
  raffle_id: string;
  bookie_id: string;
  id_mercado_pago: string;
  status: StatusTransaction;
  quota_value: number;
  amount_numbers: number;
  amount_cash: number;
  purchased: Array<number>;
  pix_copia_cola: string;
  pix_qr_code: string;
  flashNumbers?: Array<number>;

  user?: Partial<Client>;
  raffle?: Partial<Raffle>;
}

export type Transactions = Array<Transaction>;

export type TransactionBuyers = {
  id: string;
  id_mercado_pago: string;
  amount_numbers: number;
  amount_cash: number;
  purchased: Array<number>;
  user: {
    name: string;
    email: string;
    phone: string;
  }
}

export type TransactionsRaffle = Pagination & {
  transactions: Array<TransactionBuyers>;
}

export type TransactionMPResponse = {
  qrCode: string;
  qrCodeBase64: string;
  id_mercado_pago: string;
}

export type TransactionExpanded = Pick<Transaction, 'amount_cash' | 'amount_numbers' | 'status' | 'id_mercado_pago' | 'pix_copia_cola' | 'pix_qr_code' | 'purchased'> & {
  raffle: Pick<Raffle, 'id' | 'create_at' | 'description_award' | 'award' | 'name' | 'draw_prediction' | 'draw_date' | 'status'>;
  bookie: Pick<Bookie, 'id' | 'name' | 'logo'>;
};

export type StatusTransaction = 'PENDING' | 'PAID' | 'REFUND' | 'CANCELED';

export const StatusesTransaction: { [key in StatusTransaction]: string } = {
  PENDING: 'Pendente',
  PAID: 'Confirmado',
  REFUND: 'Estornado',
  CANCELED: 'Cancelado',
};

export const ColorsStatusTransaction: { [key in StatusTransaction]: Color } = {
  CANCELED: 'danger',
  PAID: 'success',
  PENDING: 'warning',
  REFUND: 'danger',
}

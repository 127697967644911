import { Injectable } from '@angular/core';
import {
  CollectionReference,
  Firestore,
  Query,
  QuerySnapshot,
  collection,
  getDocs,
  onSnapshot,
  query,
  where,
} from '@angular/fire/firestore';
import { ResetPasswordFirebase } from '../models/reset-password-firebase.model';

@Injectable({
  providedIn: 'root'
})
export class FirebaseResetPasswordService {

  private collection: CollectionReference<ResetPasswordFirebase>;

  constructor(private firestore: Firestore) {
    this.collection = collection(this.firestore, 'recover-password') as CollectionReference<ResetPasswordFirebase>;
  }

  async getPasswordResetByCode(code: string): Promise<Array<ResetPasswordFirebase>> {
    const q = query(this.collection, where('userInfo.code', '==', code));
    const snaps = await getDocs(q);
    return snaps.docs.map(doc => ({ id: doc.id, ...doc.data() }));
  }
}

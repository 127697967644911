import { Injectable } from '@angular/core';
import { AlertController } from '@ionic/angular/standalone';
import { Color } from '@ionic/core';

@Injectable({
  providedIn: 'root'
})
export class AlertService {

  constructor(private alertController: AlertController) {}

  async presentAlert(header: string, message: string, color: Color = 'primary') {
    const alert = await this.alertController.create({
      header,
      message,
      cssClass: ['color-' + color],
      buttons: [
        { text: 'OK', role: 'cancel', }
      ]
    });

    await alert.present();

    return alert;
  }

  async confirm(header: string, message: string): Promise<boolean> {
    const confirm = await this.alertController.create({
      header,
      message,
      buttons: [
        { text: 'Sim', role: 'confirmed' },
        { text: 'Não', role: 'cancel' }
      ]
    });
    await confirm.present();
    const dis = await confirm.onDidDismiss();
    return dis.role === 'confirmed';
  }
}

<div id="social">
  @if (bookie?.url_facebook) {
    <ion-button fill="clear" [href]="bookie?.url_facebook" target="_blank">
      <ion-icon color="dark" name="logo-facebook" />
    </ion-button>
  }
  @if (bookie?.url_instagram) {
    <ion-button fill="clear" [href]="bookie?.url_instagram" target="_blank">
      <ion-icon color="dark" name="logo-instagram" />
    </ion-button>
  }
  @if (bookie?.url_x) {
    <ion-button fill="clear" [href]="bookie?.url_x" target="_blank">
      <ion-icon color="dark" name="logo-twitter" />
    </ion-button>
  }
  @if (bookie?.url_tiktok) {
    <ion-button fill="clear" [href]="bookie?.url_tiktok" target="_blank">
      <ion-icon color="dark" name="logo-tiktok" />
    </ion-button>
  }
</div>

import { Injectable } from '@angular/core';
import { ModalController } from '@ionic/angular/standalone';

@Injectable({
  providedIn: 'root'
})
export class ModalService {

  constructor(private modalController: ModalController) {}

  async presentModal(component: NonNullable<Function>) {
    const modal = await this.modalController.create({
      component,
    });

    await modal.present();

    return modal;
  }
}

import { Component, Input } from '@angular/core';
import { IonCard, IonCardHeader, IonSkeletonText } from "@ionic/angular/standalone";

@Component({
  selector: 'card-total-skeleton',
  templateUrl: './card-total-skeleton.component.html',
  styleUrls: ['./card-total-skeleton.component.scss'],
  standalone: true,
  imports: [IonSkeletonText, IonCardHeader, IonCard,],
})
export class CardTotalSkeletonComponent {

  @Input() arraySize: number = 5;
  cards: Array<number> = [];

  ngOnInit() {
    this.cards = [...new Array(this.arraySize).keys()];
  }

}

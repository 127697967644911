import { Component, Input } from '@angular/core';
import { IonIcon, IonCard, IonCardHeader, IonCardTitle, IonCardSubtitle } from '@ionic/angular/standalone';
import { CurrencyPipe, DecimalPipe, PercentPipe } from '@angular/common';
import { RouterLink } from '@angular/router';
import { TotalCard, TotalCardTotal } from '../../models/card.model';
import { AbrNumberPipe } from '../../pipes';
import { StatusRaffleOptionsObj } from '../../models';
import { RaffleStatusMapPipe } from '../../pipes/raffle-status-map.pipe';

const IonComponents = [IonCardSubtitle, IonCardTitle, IonCardHeader, IonCard, IonIcon];
const Pipes = [CurrencyPipe, PercentPipe, AbrNumberPipe, DecimalPipe, RaffleStatusMapPipe];

@Component({
  selector: 'card-total',
  templateUrl: './card-total.component.html',
  styleUrls: ['./card-total.component.scss'],
  standalone: true,
  imports: [IonComponents, Pipes, RouterLink],
})
export class CardTotalComponent {
  @Input() totalCard: TotalCard;
  @Input() center: boolean = false;

  statusRaffleOptionsObj: { [key in TotalCardTotal]: string } = StatusRaffleOptionsObj as { [key in TotalCardTotal]: string };
}

import { Component, HostListener } from '@angular/core';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { IonList, IonButton, IonContent, IonAvatar, IonSpinner } from '@ionic/angular/standalone';
import { AuthService, ToastService } from '../../services';
import { BaseClass } from '../../utils';
import { APP_ID, FRASE_EFEITO, LOGO_URL, MIN_PASSWORD_LENGTH, MIN_USERNAME_LENGTH } from '../../globals';
import { AsyncPipe } from '@angular/common';
import { NgxMaskDirective } from 'ngx-mask';

@Component({
  selector: 'login-common',
  templateUrl: './login.component.html',
  standalone: true,
  imports: [IonSpinner, IonAvatar, IonContent, MatFormFieldModule, MatInputModule, IonButton, IonList, ReactiveFormsModule, RouterLink, AsyncPipe, NgxMaskDirective],
  styleUrls: ['./login.component.scss'],
})
export class LoginComponentCommon extends BaseClass() {

  title: string = null;

  form: FormGroup<{
    login: FormControl<string>;
    password: FormControl<string>;
  }> = new FormGroup({
    login: new FormControl('', [Validators.required, APP_ID?.value === 'bookie' ? Validators.email : Validators.minLength(MIN_USERNAME_LENGTH)]),
    password: new FormControl('', [Validators.required, Validators.minLength(MIN_PASSWORD_LENGTH)]),
  });

  mask = APP_ID?.value === 'client' ? '000.000.000-00' : null;

  appID = APP_ID;
  logoUrl = LOGO_URL;
  fraseEfeito = FRASE_EFEITO;

  redirect: string = null;

  logingIn: boolean = false;

  @HostListener('document:keydown.enter', ['$event'])
  onEnter(event: KeyboardEvent) {
    if ((event.target as HTMLElement).tagName === 'INPUT' && this.form.valid) this.login();
  }

  constructor(
    private toastService: ToastService,
    private authService: AuthService,
    private router: Router,
    private route: ActivatedRoute,
  ) {
    super();
  }

  ngOnInit() {
    this.redirect = this.route?.snapshot?.queryParams?.['redirect'] || '/home';

    switch (APP_ID?.value) {
      case 'admin':
        this.title = 'Admin';
        break;
      case 'bookie':
        this.title = 'Rifeiro';
        break;
    }
  }

  login() {
    const formValue = this.form.value;
    switch (APP_ID?.value) {
      case 'admin':
        if (formValue?.login?.length < MIN_USERNAME_LENGTH) return this.toastService.present({ message: 'O login deve ter no mínimo 3 caracteres.', color: 'danger' });
        break;

      case 'bookie':
        if (this.form.controls.login.invalid) return this.toastService.present({ message: 'E-mail inválido', color: 'danger' });
        break;

      case 'client':
        if (formValue.login?.length !== 11) return this.toastService.present({ message: 'CPF inválido', color: 'danger' });
        break;
    }
    if (formValue.password?.length < MIN_PASSWORD_LENGTH) return this.toastService.present({ message: `Senha deve ter no mínimo ${MIN_PASSWORD_LENGTH} caracteres`, color: 'danger' });

    this.form.disable();
    this.logingIn = true;

    const { login, password } = formValue;

    this.authService.login(login, password, APP_ID?.value === 'client').then((res) => {
      if (res?.['token']) {
        this.authService.setSession({ ...res, timestamp: Date.now() });
        this.router.navigateByUrl(this.redirect, { replaceUrl: true });
      }
    }).catch((err) => {
      console.log(err);
      this.toastService.present({ message: err['status'] === 401 ? 'Login ou senha inválidos' : 'Ocorreu um erro ao tentar fazer login, tente novamente.', color: 'danger' });
    }).finally(() => {
      this.form.enable();
      this.logingIn = false;
    });
  }

}

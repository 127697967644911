import { Component, Input } from '@angular/core';
import { IonList, IonListHeader, IonText, IonItem, IonLabel, IonBadge, IonProgressBar, IonNote, IonIcon, IonSkeletonText } from "@ionic/angular/standalone";
import { Router, RouterLink } from '@angular/router';
import { CurrencyPipe, DatePipe, PercentPipe, TitleCasePipe } from '@angular/common';
import { RaffleStatusColorMap, Raffles } from '../../models';
import { AbrNumberPipe } from '../../pipes';
import { BaseClass } from '../../utils';
import { AppID } from '../../api';
import { RaffleStatusMapPipe } from '../../pipes/raffle-status-map.pipe';

const IonComponents = [IonProgressBar, IonBadge, IonLabel, IonItem, IonText, IonListHeader, IonList, IonSkeletonText, IonIcon, IonNote,];
const Pipes = [DatePipe, TitleCasePipe, PercentPipe, CurrencyPipe, AbrNumberPipe, RaffleStatusMapPipe];

@Component({
  selector: 'raffles-list',
  templateUrl: './raffles-list.component.html',
  styleUrls: ['./raffles-list.component.scss'],
  standalone: true,
  imports: [RouterLink, IonComponents, Pipes],
})
export class RafflesListComponent extends BaseClass() {

  @Input() showAllButton: boolean = false;
  @Input() raffles: Raffles;

  @Input() app: AppID;

  raffleStatusColorMap = RaffleStatusColorMap;

  constructor(
    private router: Router,
  ) {
    super();
  }

  goToRaffle(raffle_id: string) {
    this.router.navigate(this.app === 'client' ? ['/home', 'promotion', btoa(raffle_id)] : ['/home', 'raffles', raffle_id]);
  }
}

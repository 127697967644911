// import { randomNumber } from "../../utils/utils";
import { FormControl } from "@angular/forms";
import { Bookie } from "./bookie.model";
import { Color } from "@ionic/core";
import { Client } from "./client.model";

export type Raffle = {
  id?: string;
  create_at?: Date | string;
  description_award: string;
  award: string;
  name: string;
  numbers: Array<number>;
  amount_numbers: number;
  draw_prediction: Date | string;
  qtd_array: number;
  quota_value: number;
  draw_date?: Date | string;
  status: RaffleStatus;
  total_sellings?: number;
  start_at: number;
  image: Array<string>;
  drawn_number?: number;

  bookie?: Bookie;
  bookie_id?: string;

  winner_user?: string;
  user?: Partial<Client>;
}

export type RaffleStatus = 'ACTIVE' | 'CLOSED' | 'PENDING' | 'SOLDOUT';

export type FlashNumberRaffle = {
  id: string;
  flash_number: number;
  raffle_id: string;
  user_id?: string;
  locked: boolean;

  user?: Partial<Bookie>;
}

export const StatusRaffleOptionsObj: { [key in RaffleStatus]: string } = {
  ACTIVE: 'Ativa',
  CLOSED: 'Finalizada',
  PENDING: 'Pendente',
  SOLDOUT: 'Esgotada',
};

export const StatusRaffleOptionsArr: Array<{ label: string, value: RaffleStatus }> = [
  { label: 'Ativas', value: 'ACTIVE' },
  { label: 'Finalizadas', value: 'CLOSED' },
  { label: 'Pendentes', value: 'PENDING' },
  { label: 'Esgotadas', value: 'SOLDOUT' },
];

export const RaffleStatusColorMap: { [key in RaffleStatus]: Color } = {
  ACTIVE: 'success',
  PENDING: 'warning',
  CLOSED: 'primary',
  SOLDOUT: 'dark',
};

export type Raffles = Array<Raffle>;

// type FormOrValue<K extends keyof Raffle, T = 'form' | 'raffle'> = T extends 'form' ? FormControl : Raffle[K];

export type TRaffleForm = {
  amount_numbers: FormControl,
  award: FormControl,
  description_award: FormControl,
  draw_prediction: FormControl,
  name: FormControl,
  quota_value: FormControl,
  start_at: FormControl,
};

import { Injectable } from '@angular/core';
import { AppID, Session, session$ } from '../api';

@Injectable({
  providedIn: 'root'
})
export class SessionStorageService {

  constructor() {}

  set(key: string, value: string | number | boolean | object) {
    sessionStorage.setItem(key, JSON.stringify(value));
  }

  get<T>(key: string) {
    return JSON.parse(sessionStorage.getItem(key)) as T;
  }

  remove(key: string) {
    sessionStorage.removeItem(key);
  }

  setSession<T extends AppID>(session: Session<T>) {
    session$.next(session);
    return this.set('session', session);
  }

  getSession<T extends AppID>():Session<T> {
    const session = this.get<Session<T>>('session') || null;
    session$.next(session);
    return session;
  }

  removeSession() {
    session$.next(null);
    return this.remove('session');
  }
}

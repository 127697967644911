import { Component, Input } from '@angular/core';
import { IonList, IonText, IonSkeletonText, IonNote, IonProgressBar, IonBadge, IonAvatar, IonImg, IonIcon } from "@ionic/angular/standalone";
import { RouterLink } from '@angular/router';
import { NgxMaskPipe } from 'ngx-mask';
import { addIcons } from 'ionicons';
import { person } from 'ionicons/icons';
import { BaseClass } from '@common';
import { Bookies } from '@common';

const IonicComponents = [IonList, IonText, IonSkeletonText, IonBadge, IonProgressBar, IonNote, IonAvatar, IonImg, IonIcon];

addIcons({ person })

@Component({
  selector: 'bookies-list',
  templateUrl: './bookies-list.component.html',
  styleUrls: ['./bookies-list.component.scss'],
  standalone: true,
  imports: [IonicComponents, RouterLink, NgxMaskPipe],
})
export class BookiesListComponent extends BaseClass() {
  @Input() showAllButton: boolean = false;
  @Input() bookies: Bookies;
}

// export const DB_NAME = 'rf';
// export const TABLE_BOOKIES = 'bookies';
// export const TABLE_USERS = 'users';
// export const TABLE_RAFFLES = 'raffles';

import { BehaviorSubject } from "rxjs";
import { Session } from "./api.model";

export type AppID = 'admin' | 'bookie' | 'client';

// const API_URL = `https://3937888812b1d2.lhr.life`;
// const API_URL = `${location.protocol}//${location.hostname}:3333`
// const API_URL = `https://2ca7f165d7af38.lhr.life`
// const API_URL = 'https://125e-168-195-1-19.ngrok-free.app';
// const API_URL = `http://localhost:3333`
// const API_URL = 'https://portal-da-sorte.onrender.com';
const API_URL = 'https://api.portaldasorte.site';
const API_COLLECTIONS = {
  globals: 'globals',
  bookie: 'bookie',
  admin: 'admin',
  client: 'user',
  raffle: 'raffle',
  payment: 'payment',
  auth: 'auth',
};

export const API = {
  ENPOINTS: {
    globals: {
      cadExists: `${API_URL}/${API_COLLECTIONS.globals}/cadExists`,
      phoneExist: `${API_URL}/${API_COLLECTIONS.globals}/phoneExist`,
      allUsers: `${API_URL}/${API_COLLECTIONS.globals}/allUsers`,
      allCreators: `${API_URL}/${API_COLLECTIONS.globals}/allCreators`,
      allRaffles: `${API_URL}/${API_COLLECTIONS.globals}/allRaffles`,
    },
    auth: {
      loginAdmin: `${API_URL}/${API_COLLECTIONS.auth}/me/ad`,
      loginBookie: `${API_URL}/${API_COLLECTIONS.auth}/me/bookie`,
      loginClient: `${API_URL}/${API_COLLECTIONS.auth}/me`,
      refreshToken: `${API_URL}/token/refresh`,
      requestPasswordRecover: `${API_URL}/recover/password`,
      updatePassword: `${API_URL}/change/password`,
    },
    client: {
      create: `${API_URL}/create/${API_COLLECTIONS.client}`,
      update: `${API_URL}/${API_COLLECTIONS.client}/update`,
      delete: `${API_URL}/${API_COLLECTIONS.client}/delete`,
      promotion: `${API_URL}/promotion`,
      cadExists: `${API_URL}/cadExists`,
      purchasedRaffles: `${API_URL}/${API_COLLECTIONS.client}/search/raffles/buy/purchased`,
    },
    bookie: {
      create: `${API_URL}/${API_COLLECTIONS.bookie}/create`,
      update: `${API_URL}/${API_COLLECTIONS.bookie}/update`,
      delete: `${API_URL}/${API_COLLECTIONS.bookie}/delete`,
      buyers: `${API_URL}/${API_COLLECTIONS.bookie}/raffle/buyers`,
      flashNumbersRaffle: `${API_URL}/${API_COLLECTIONS.bookie}/all-flash-numbers`,
      unlockFlashNumberRaffle: `${API_URL}/${API_COLLECTIONS.raffle}/release/flash-numbers`,
      finishRaffle: `${API_URL}/${API_COLLECTIONS.bookie}/close-raffle`,
      count: `${API_URL}/${API_COLLECTIONS.bookie}/count`,
      grossRevenue: `${API_URL}/${API_COLLECTIONS.bookie}/all-selling/value`,
      get: `${API_URL}/`,
    },
    admin: {
      create: `${API_URL}/${API_COLLECTIONS.admin}/create`,
      countBookie: `${API_URL}/${API_COLLECTIONS.admin}/count`,
      approveRaffle: `${API_URL}/${API_COLLECTIONS.admin}/approve/raffle`,
      allTimeGrossRevenue: `${API_URL}/${API_COLLECTIONS.admin}/all-selling/value`,
    },
    raffle: {
      '': `${API_URL}/${API_COLLECTIONS.raffle}`,
      raffleById: `${API_URL}/${API_COLLECTIONS.raffle}/`,
      rafflesByBookieId: `${API_URL}/${API_COLLECTIONS.raffle}/`,
      create: `${API_URL}/${API_COLLECTIONS.raffle}/create`,
      delete: `${API_URL}/${API_COLLECTIONS.raffle}/delete`,
      update: `${API_URL}/${API_COLLECTIONS.raffle}/update`,
      count: `${API_URL}/${API_COLLECTIONS.raffle}/count/`,
      insertFlashNumbers: `${API_URL}/${API_COLLECTIONS.raffle}/verify/flash-numbers`,
    },
    transaction: {
      getById: `${API_URL}/${API_COLLECTIONS.client}/search/transaction/purchased`,
      getByIdMP: `${API_URL}/${API_COLLECTIONS.client}/search/transaction/purchased/mp`,
    },
    payment: {
      create: `${API_URL}/${API_COLLECTIONS.payment}/create/payment/pix`,
      connectUrl: `${API_URL}/${API_COLLECTIONS.payment}/url/connect`,
    }
  },
};

export const session$: BehaviorSubject<Session<AppID>> = new BehaviorSubject<Session<AppID>>(null);

export const getCurrentSession = <T extends AppID>() => {
  return session$ as BehaviorSubject<Session<T>>;
}

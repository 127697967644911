import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import {
  IonList,
  IonSkeletonText,
  IonItem,
  IonImg,
  IonAvatar,
  IonCard,
  IonCardSubtitle,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonButtons,
  IonButton,
  IonIcon,
  IonText,
  IonNote,
  IonChip,
} from "@ionic/angular/standalone";
import { NgxMaskDirective } from 'ngx-mask';
import { saveOutline, imageOutline, addOutline, repeatOutline, linkOutline } from 'ionicons/icons';
import { addIcons } from 'ionicons';
import { ActivatedRoute, Router } from '@angular/router';
import { Bookie, ConnectMercadoPago, FirebaseStorageService, LoadingService, PaymentService, RaffleStatus, StatusRaffleOptionsArr, TFormProfileBookie, copyClipboard } from '@common';
import { AlertService, BookieService, RaffleService, ToastService } from '@common';
import { BaseClass, cpfIsValid, hashPassword } from '@common';
import { CardTotalSkeletonComponent } from '@common/components';

addIcons({ saveOutline, imageOutline, addOutline, repeatOutline, linkOutline });

@Component({
  selector: 'bookie-form-add-skeleton',
  templateUrl: './bookie-form-add-skeleton.component.html',
  styleUrls: ['./bookie-form-add.component.scss'],
  standalone: true,
  imports: [IonChip, IonNote, IonAvatar, IonCard, IonCardHeader, MatFormFieldModule, MatInputModule, IonSkeletonText, IonList, CardTotalSkeletonComponent],
})
export class BookieFormAddSkeletonComponent extends BaseClass() {
  formFields: Array<number> = [...new Array(5).keys()];
}

const ION_COMPONENTS = [IonCardTitle, IonCardHeader, IonCardContent, IonCardSubtitle, IonCard, IonAvatar, IonImg, IonItem, IonList, IonText, IonIcon, IonButton, IonButtons, IonSkeletonText, IonChip];
@Component({
  selector: 'bookie-form-add',
  templateUrl: './bookie-form-add.component.html',
  styleUrls: ['./bookie-form-add.component.scss'],
  standalone: true,
  imports: [ION_COMPONENTS, MatFormFieldModule, ReactiveFormsModule, MatInputModule, NgxMaskDirective, CardTotalSkeletonComponent],
})
export class BookieFormAddComponent extends ConnectMercadoPago {

  @Output() listRaffles: EventEmitter<RaffleStatus> = new EventEmitter<RaffleStatus>();

  imageBase64: string;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private alertService: AlertService,
    private bookieService: BookieService,
    private raffleService: RaffleService,
    private firebaseStorageService: FirebaseStorageService,
  ) {
    super();
  }

  @Input() bookie: Bookie;

  form = new FormGroup<Omit<TFormProfileBookie, 'payment_url'>>({
    name: new FormControl(null, [Validators.required, Validators.minLength(3)]),
    email: new FormControl(null, [Validators.required, Validators.email]),
    cpf: new FormControl(null, [Validators.required, Validators.minLength(11)]),
    phone: new FormControl(null, [Validators.required, Validators.minLength(11)]),
    url_connect_mp: new FormControl(null, []),
    url_facebook: new FormControl(null, []),
    url_instagram: new FormControl(null, []),
    url_tiktok: new FormControl(null, []),
    url_x: new FormControl(null, []),
  });

  cards: Array<{ label: string, count: number, value: RaffleStatus }>;

  ngOnInit() {
    if (this.bookie) {
      this.form.patchValue(this.bookie);
      this.getStats();
    }
  }

  private async getStats() {
    this.cards = await Promise.all(StatusRaffleOptionsArr.map(async (status) => {
      return {
        label: status.label,
        value: status.value,
        count: (await this.raffleService.getCountRaffles(this.bookie.id, status.value)).count,
      }
    }));
  }

  cancel() {
    this.router.navigate(['..'], { relativeTo: this.route });
  }

  seeRaffles(status: RaffleStatus) {
    this.listRaffles.emit(status);
  }

  selectLogo() {
    const input = document.createElement('input');
    input.type = 'file';
    input.accept = 'image/*';
    input.onchange = (e) => {
      const file = (e.target as HTMLInputElement).files[0];
      const reader = new FileReader();
      reader.onload = (e) => {
        this.imageBase64 = e.target.result as string;
      }
      reader.readAsDataURL(file);
    }
    input.click();
  }

  async save() {
    // if (this.bookie?.id) return this.toastService.present({ message: 'Não é possível alterar dados do rifeiro', color: 'danger' });

    const errors: Array<string> = [];

    const { name, email, cpf, phone } = this.form.controls;

    if (name.invalid) errors.push('Nome deve ter no mínimo 3 caracteres');
    if (email.invalid) errors.push('E-mail inválido');
    if (cpf.invalid || !cpfIsValid(cpf.value)) errors.push('CPF inválido');
    if (phone.invalid) errors.push('Telefone inválido');
    // if (payment_url.invalid) errors.push('URL de pagamento inválida');

    if (errors.length) return this.alertService.presentAlert('Os seguintes erros foram encontrados: ', errors.join('\n'), 'danger');

    const bookie: Bookie = Object.fromEntries(Object.entries({
      name: name.value,
      email: email.value,
      cpf: cpf.value,
      phone: phone.value,
    }).map(([key, value]) => {
      return [key, value || null]
    }));

    const loading = await this.loadingService.presentLoading("Salvando rifeiro... Aguarde");

    const fileType = this.imageBase64?.split(';')[0].split('/')[1];
    const fullPath = fileType ? `bookies/${cpf.value}-${Date.now()}.${fileType}` : null;

    let logo = '';

    this.form.disable();

    try {
      if (this.imageBase64) {
        const upload = await this.firebaseStorageService.uploadFromBase64(fullPath, this.imageBase64);
        const uploadPath = upload.ref.fullPath;
        logo = await this.firebaseStorageService.getDownloadURL(uploadPath);
      }
    } catch (err) {
      console.error('save', err);
      this.toastService.present({ message: "Erro ao salvar imagem", color: "danger" });
    } finally {
      (this.bookie?.id ? this.bookieService.updateBookie(this.bookie.id, bookie) : this.bookieService.addBookie({
        ...bookie,
        logo,
        payment_url: '',
        password: hashPassword(`${bookie.name.split(' ')[0].toLowerCase()}@${bookie.cpf.slice(7, 11)}`),
      }))
        .then((res) => {
          console.log(res)
          this.toastService.present({ message: 'Rifeiro cadastrado com sucesso', color: 'success' });
          this.router.navigate(['/home', 'bookies'], { replaceUrl: true });
        }).catch((err) => {
          console.error('save', err);
          this.firebaseStorageService.deleteFile(fullPath);
          let message = 'Não foi possível cadastrar o rifeiro';
          if (err.error?.message) message = err.error.message;
          this.toastService.present({ message, color: 'danger' });
        }).finally(() => {
          loading.dismiss();
          this.form.enable();
        });
    }
  }
}

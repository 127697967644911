<ion-list mode="md" class="table-list">
  @if (isDesktop) {
    <div class="header row border-top-radius-md">
      <ion-text class="image" />
      <ion-text class="cpf"> CPF </ion-text>
      <ion-text class="name"> Nome </ion-text>
      <ion-text class="email"> e-mail </ion-text>
      <ion-text class="phone"> Telefone </ion-text>
    </div>
  }

  <div class="body">
    @if (!bookies) {
      <div class="row ion-text-center">
        <ion-text class="padding-block-md"> <ion-skeleton-text animated /></ion-text>
        <ion-text class="padding-block-md"> <ion-skeleton-text animated /></ion-text>
        <ion-text class="padding-block-md"> <ion-skeleton-text animated /></ion-text>
        <ion-text class="padding-block-md"> <ion-skeleton-text animated /></ion-text>
        <ion-text class="padding-block-md"> <ion-skeleton-text animated /></ion-text>
      </div>
    }

    @for (bookie of bookies; track $index) {
      <div class="row hover-pointer" [routerLink]="['/home', 'bookies', bookie.id]">
        <ion-text class="image">
          <ion-avatar>
            <ion-img [src]="bookie?.logo || 'https://ui-avatars.com/api/?name=' + bookie.name" />
          </ion-avatar>
        </ion-text>
        <ion-text class="cpf"> {{ bookie.cpf | mask: "CPF_CNPJ" }} </ion-text>
        <ion-text class="name"> {{ bookie.name }} </ion-text>
        <ion-text class="email"> {{ bookie.email }} </ion-text>
        <ion-text class="phone"> {{ bookie.phone | mask: "(00) 0000-0000||(00) 0 0000-0000" }} </ion-text>
      </div>
    }

    @if (bookies?.length === 0) {
      <div class="footer row ion-text-center padding-block-sm">
        <ion-text>
          <ion-note> Nenhum resultado para os filtros selecionados </ion-note>
        </ion-text>
      </div>
    }

    @if (showAllButton) {
      <div class="footer row hover-pointer ion-text-center padding-block-sm" [routerLink]="['/home', 'bookies']">
        <ion-text>
          <ion-note class="linear-g-color"> VER TODOS </ion-note>
        </ion-text>
      </div>
    }
  </div>
</ion-list>

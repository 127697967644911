import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { API, Session, AppID } from '../api';
import { SessionStorageService } from './session-storage.service';
import { APP_ID } from '../globals';
import { hashPassword } from '../utils';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(
    private httpClient: HttpClient,
    private sessionStorageService: SessionStorageService,
  ) {}

  login<T extends AppID>(login: string, clearPassword: string, loginIsCPF: boolean = false) {
    let endpoint: string;

    const password = hashPassword(clearPassword);

    const body = {
      password,
    };

    switch (APP_ID?.value) {
      case 'admin':
        endpoint = API.ENPOINTS.auth.loginAdmin;
        Object.assign(body, { login });
        break;
      case 'bookie':
        endpoint = API.ENPOINTS.auth.loginBookie;
        Object.assign(body, { email: login });
        break;
      case 'client':
        endpoint = API.ENPOINTS.auth.loginClient;
        Object.assign(body, loginIsCPF ? { cpf: login } : { email: login });
        break;
    }

    return lastValueFrom(this.httpClient.post<Omit<Session<T>, 'timestamp'>>(endpoint, body, { withCredentials: true, }));
  }

  logout() {
    return this.sessionStorageService.removeSession();
  }

  requestPasswordReset(appId: AppID, opt: { email?: string, cpf?: string }) {
    const endpoint = API.ENPOINTS.auth.requestPasswordRecover;
    return lastValueFrom(this.httpClient.get<{ response: number, status: string }>(`${endpoint}/${appId}/${opt.email || opt.cpf}`));
  }

  updatePassword(cpf: string, password: string, identificador: AppID, code: string) {
    const endpoint = API.ENPOINTS.auth.updatePassword;
    return lastValueFrom(this.httpClient.patch<{ response: number, status: string }>(endpoint, { cpf, password, identificador, code }));
  }

  getClientByCPF(cpf: string) {
    return lastValueFrom(this.httpClient.get<{ error: string, response: boolean }>(`${API.ENPOINTS.client.cadExists}/`, { params: { cpf } }));
  }

  refreshToken() {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'getSetCookie': 'true',
    });
    return lastValueFrom(this.httpClient.get<{ token: string }>(API.ENPOINTS.auth.refreshToken, { withCredentials: true, headers }));
  }

  setSession<T extends AppID>(session: Session<T>) {
    return this.sessionStorageService.setSession(session);
  }

  getSession<T extends AppID>(): Session<T> {
    return this.sessionStorageService.getSession();
  }
}

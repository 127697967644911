import { Injectable } from '@angular/core';
import { BaseHttpService } from './base-http.service';
import { Client } from '../models';
import { API } from '../api';

@Injectable({
  providedIn: 'root'
})
export class ClientService extends BaseHttpService {

  create(client: Client) {
    return this.POST_NOAUTH<{ response?: number, message: string, id?: string }>(API.ENPOINTS.client.create, client);
  }

  update(id: string, client: Client) {
    return this.PUT<{ response?: number, message: string }>(`${API.ENPOINTS.client.update}/?id=${id}`, client);
  }

}

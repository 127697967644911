import { Injectable } from '@angular/core';
import { BaseHttpService } from './base-http.service';
import { API } from '../api';

@Injectable({
  providedIn: 'root'
})
export class AdminService  extends BaseHttpService {
  getCountBookies() {
    return this.GET<{ message: string; count: number }>(API.ENPOINTS.admin.countBookie);
  }

  getAllTimeGrossRevenue() {
    return this.GET<{ message: string; totalSelling: number }>(API.ENPOINTS.admin.allTimeGrossRevenue);
  }
}

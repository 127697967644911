import { Component, OnInit } from '@angular/core';
import { IonHeader, IonToolbar, IonTitle, IonContent } from "@ionic/angular/standalone";
import { BookieFormAddComponent } from '../bookie-form-add/bookie-form-add.component';

@Component({
  selector: 'app-bookie-add',
  templateUrl: './bookie-add.component.html',
  styleUrls: ['./bookie-add.component.scss'],
  standalone: true,
  imports: [IonContent, IonTitle, IonToolbar, IonHeader, BookieFormAddComponent],
})
export class BookieAddComponent  implements OnInit {

  constructor() { }

  ngOnInit() {}

}

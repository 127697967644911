<ion-list id="form" lines="none" mode="md" [formGroup]="form" class="margin-md" [class.skeleton]="!cards">
  <ion-avatar id="avatar">
    @if (bookie.logo) {
      <ion-img [src]="bookie?.logo" alt="Avatar" />
    } @else {
      @if (imageBase64) {
        <ion-img [src]="imageBase64" alt="Avatar" />
      } @else {
        <ion-icon color="medium" name="image-outline" />
      }
      <ion-button fill="solid" (click)="selectLogo()">
        <ion-icon slot="icon-only" [name]="imageBase64 ? 'repeat-outline' : 'add-outline'" />
      </ion-button>
    }
  </ion-avatar>

  @if (bookie?.id) {
    <div id="cards">
      @if (cards) {
        @for (card of cards; track $index) {
          <ion-card mode="md" (click)="seeRaffles(card.value)" class="stat">
            <ion-card-header>
              <h6 class="text-ellipsis value">{{ card.count }}</h6>
              <h5 class="text-ellipsis label">Rifas {{ card.label }}</h5>
            </ion-card-header>
          </ion-card>
        }
      } @else {
        <card-total-skeleton [arraySize]="5" />
      }
    </div>
  }

  <mat-form-field id="name" appearance="outline">
    <input matInput placeholder="Nome" formControlName="name" />
  </mat-form-field>

  <mat-form-field id="cpf" appearance="outline">
    <input matInput placeholder="CPF" formControlName="cpf" mask="000.000.000-00" />
  </mat-form-field>

  <mat-form-field id="email" appearance="outline">
    <input matInput placeholder="e-mail" formControlName="email" [readonly]="bookie?.id" />
  </mat-form-field>

  <mat-form-field id="phone" appearance="outline">
    <input matInput placeholder="Telefone" formControlName="phone" mask="(00)0000-0000||(00) 0 0000-0000" />
  </mat-form-field>

  @if (bookie?.id) {
    <mat-form-field id="url_facebook" appearance="outline">
      <input matInput placeholder="Facebook" formControlName="url_facebook" readonly />
    </mat-form-field>
    <mat-form-field id="url_instagram" appearance="outline">
      <input matInput placeholder="Instagram" formControlName="url_instagram" readonly />
    </mat-form-field>
    <mat-form-field id="url_x" appearance="outline">
      <input matInput placeholder="X" formControlName="url_x" readonly />
    </mat-form-field>
    <mat-form-field id="url_tiktok" appearance="outline">
      <input matInput placeholder="TikTok" formControlName="url_tiktok" readonly />
    </mat-form-field>
  }

  @if (bookie?.id) {
    @if (!bookie?.payment_url) {
      <mat-form-field id="url_connect_mp" appearance="outline">
        <input class="margin-md" [class.italic]="!form.controls.url_connect_mp?.value" matInput placeholder="URL de conexão com Mercado Pago ainda não gerada" formControlName="url_connect_mp" [readonly]="true" />

        @if (!form.controls.url_connect_mp?.value) {
          <ion-button fill="solid" slot="end" (click)="connectMercadoPago(form, bookie)">
            <ion-text> Gerar URL </ion-text>
          </ion-button>
        } @else {
          <ion-button fill="solid" slot="end" (click)="copyURLConnectMP(bookie)">
            <ion-text> Copiar </ion-text>
          </ion-button>
        }
      </mat-form-field>
    } @else {
      <ion-chip [outline]="false" id="payment_url" class="italic padding-vertical-md" color="success">Esse rifeiro está conectado ao Mercado Pago</ion-chip>
    }
  }

  <ion-buttons id="actions" class="space-between">
    <ion-button (click)="cancel()" fill="clear">
      <ion-text> {{ bookie?.id ? "VOLTAR" : "CANCELAR" }} </ion-text>
    </ion-button>

    <ion-button (click)="save()" fill="solid">
      <ion-text> SALVAR </ion-text>
      <ion-icon slot="end" name="save-outline" />
    </ion-button>
  </ion-buttons>
</ion-list>

import { Component, OnInit } from '@angular/core';
import { BaseClass, cpfIsValid, hashPassword } from '../../utils';
import { ActivatedRoute, Router } from '@angular/router';
import { IonContent, IonList, IonAvatar, IonText, IonButton } from "@ionic/angular/standalone";
import { APP_ID, FRASE_EFEITO, LOGO_URL, MIN_CHARACTERS_PASSWORD } from '../../globals';
import { MatFormFieldModule } from '@angular/material/form-field';
import { AsyncPipe } from '@angular/common';
import { FormControl, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { NgxMaskDirective } from 'ngx-mask';
import { MatInputModule } from '@angular/material/input';
import { AlertService, AuthService, FirebaseResetPasswordService, LoadingService, ToastService } from '../../services';
import { ResetPasswordFirebase } from '../../models/reset-password-firebase.model';

@Component({
  selector: 'lib-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss'],
  standalone: true,
  imports: [IonButton, IonText, IonAvatar, IonList, IonContent, MatFormFieldModule, MatInputModule, ReactiveFormsModule, AsyncPipe, NgxMaskDirective],
})
export class ChangePasswordComponent extends BaseClass() implements OnInit {

  logoUrl = LOGO_URL;
  fraseEfeito = FRASE_EFEITO;

  appId = APP_ID;

  emailControl: FormControl<string> = new FormControl('', [Validators.required, Validators.email]);
  cpfControl: FormControl<string> = new FormControl('', [Validators.required, Validators.minLength(11), Validators.maxLength(11)]);

  passwordControl: FormControl<string> = new FormControl('', [Validators.required, Validators.minLength(MIN_CHARACTERS_PASSWORD)]);
  passwordConfirmControl: FormControl<string> = new FormControl('', [Validators.required, Validators.minLength(MIN_CHARACTERS_PASSWORD)]);

  resetPasswordFirebase: ResetPasswordFirebase;

  loading: HTMLIonLoadingElement;

  recoverCode: string;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private firebaseResetPasswordService: FirebaseResetPasswordService,
    private authService: AuthService,
    private toastService: ToastService,
    private alertService: AlertService,
    private loadingService: LoadingService,
  ) {
    super();
  }

  ngOnInit() {
    this.loadingService.presentLoading("Verificando link...").then(loading => this.loading = loading);
    this.recoverCode = this.route.snapshot.params?.['recoverCode'];
    if (this.recoverCode) this.getEmail();
  }

  getEmail() {
    this.firebaseResetPasswordService.getPasswordResetByCode(this.recoverCode).then(res => {
      if (!res.length) {
        this.alertService.presentAlert('Código inválido', 'O código de recuperação de senha informado é inválido. Solicite um novo código.');
        return this.router.navigate(['/forgot-password']);
      }
      this.resetPasswordFirebase = res[0];
    }).finally(() => this.loading?.dismiss());
  }

  async save() {
    if (this.appId?.value === 'admin') {
      if (!this.emailControl.valid) return this.showErrorToast('E-mail inválido');
    } else {
      if (!this.cpfControl.valid || !cpfIsValid(this.cpfControl.value)) return this.showErrorToast('CPF inválido');
    }

    if (this.resetPasswordFirebase?.userInfo?.cpf !== this.cpfControl.value) return this.alertService.presentAlert(
      'CPF inválido',
      'Parece que o CPF informado não corresponde ao CPF do link de recuperação de senha. Verifique o CPF e tente novamente.'
    );

    if (this.passwordControl.value?.length < MIN_CHARACTERS_PASSWORD) return this.showErrorToast(`A senha deve ter no mínimo ${MIN_CHARACTERS_PASSWORD} caracteres`);
    if (this.passwordConfirmControl.value?.length < MIN_CHARACTERS_PASSWORD) return this.showErrorToast(`A confirmação da senha deve ter no mínimo ${MIN_CHARACTERS_PASSWORD} caracteres`);
    if (this.passwordControl.value !== this.passwordConfirmControl.value) return this.showErrorToast('As senhas não conferem');

    const loading = await this.loadingService.presentLoading('Alterando senha...');

    this.authService.updatePassword(this.appId.value === 'admin' ? this.emailControl.value : this.cpfControl.value, hashPassword(this.passwordControl.value), this.appId.value, this.recoverCode).then((res) => {
      if (res.response === 201) {
        this.toastService.present({ message: 'Senha alterada com sucesso', color: 'success' });
        this.router.navigate(['/login']);
      } else {
        this.showErrorToast('Erro ao alterar senha');
      }
    }).catch((err) => {
      this.showErrorToast('Erro ao alterar senha');
      console.log(err);
    }).finally(() => {
      loading.dismiss();
    });
  }

  private showErrorToast(message: string) {
    this.toastService.present({ message, color: 'danger' });
  }

}

import { Component, OnInit } from '@angular/core';
import { IonContent } from "@ionic/angular/standalone";
import { BaseClass, RafflesListComponent } from '@common';
import { Raffles } from '@common';
import { RaffleService } from '@common';

@Component({
  selector: 'app-raffles',
  templateUrl: './raffles.component.html',
  styleUrls: ['./raffles.component.scss'],
  standalone: true,
  imports: [IonContent, RafflesListComponent],
})
export class RafflesComponent extends BaseClass() implements OnInit {

  raffles: Raffles;

  constructor(
    private raffleService: RaffleService,
  ) {
    super();
  }

  ngOnInit() {
    this.getRaffles();
  }

  getRaffles() {
    this.raffleService.getRaffles().then((res) => {
      this.raffles = res.raffles;
    }).catch((err) => {
      console.error('getRaffles', err);
    });
  }

}

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'minMaxNumber',
  standalone: true
})
export class MinMaxNumberPipe implements PipeTransform {

  transform(value: Array<number>, method: 'min' | 'max'): number {
    if (value == null || !method) return null;
    return method === 'max' ? Math.max(...value) : Math.min(...value);
  }

}

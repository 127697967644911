import { Injectable } from '@angular/core';
import { BaseHttpService } from './base-http.service';
import { API } from '../api';
import { TransactionMPResponse, TransactionCreate } from '../models/transaction.model';
import { TAXA_MERCADO_PAGO } from '../globals';

@Injectable({
  providedIn: 'root'
})
export class PaymentService extends BaseHttpService {

  createPIX(transaction: TransactionCreate) {
    return this.POST<{ response: number, message: TransactionMPResponse }>(API.ENPOINTS.payment.create, transaction);
  }

  connectURLMP(bookieID: string) {
    return this.GET<{ message: string, response: number, url: string }>(`${API.ENPOINTS.payment.connectUrl}/${bookieID}`);
  }

}

<ion-content>
  <div id="container">
    <ion-list id="left">
      <div class="align-center justify-center">
        <ion-avatar class="margin-bottom-lg">
          <img [src]="logoUrl" />
        </ion-avatar>

        @if (!isMobile) {
          <h1 class="linear-g-color">Portal da Sorte</h1>

          <p>{{ fraseEfeito }}</p>
        }
      </div>
    </ion-list>

    <ion-list id="right" [formGroup]="form">
      <div>
        <section id="header">
          <h1>
            Bem Vindo
            @if (title) {
              <span class="linear-g-color">{{ title }}</span>
            }
            !
          </h1>
          <h3>Faça login para continuar</h3>
        </section>

        <section id="form">
          <mat-form-field id="login" appearance="outline">
            <input matInput [placeholder]="(appID | async) === 'bookie' ? 'E-mail' : (appID | async) === 'client' ? 'CPF' : 'Login'" formControlName="login" [mask]="(appID | async) === 'client' ? '000.000.000-00' : ''" />
          </mat-form-field>

          <mat-form-field id="password" appearance="outline">
            <input matInput placeholder="Senha" formControlName="password" type="password" />
          </mat-form-field>

          <ion-button [disabled]="form.disabled" fill="solid" (click)="login()">
            <span>ENTRAR</span>
            @if (logingIn) {
              <ion-spinner class="margin-start-md" name="dots" slot="end" />
            }
          </ion-button>
        </section>

        <section class="flex space-between">
          <a [routerLink]="['/forgot-password']"> Esqueceu a senha? </a>
          @if ((appID | async) === "client") {
            <a [routerLink]="['/register']" [queryParams]="{ redirect }"> Criar conta </a>
          }
        </section>
      </div>
    </ion-list>

    @if (!isMobile) {
      <hr />
    }
  </div>
</ion-content>

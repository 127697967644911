<ion-list lines="none" mode="md" class="details-raffle-form margin-md">
  <div id="images">
    <section class="images">
      @for (image of raffle.image; track $index) {
        <div class="image">
          <img [src]="image" />
        </div>
      }
    </section>
  </div>

  <div id="cards">
    @for (card of cards; track $index) {
      <card-total [totalCard]="card" [center]="true" />
    }
  </div>

  <mat-form-field id="name" appearance="outline">
    <label>Nome</label>
    <input matInput placeholder="Nome" [value]="raffle.name" readonly />
  </mat-form-field>

  <mat-form-field id="award" appearance="outline">
    <label>Prêmio</label>
    <input matInput placeholder="Prêmio" [value]="raffle.award" readonly />
  </mat-form-field>

  <mat-form-field id="bookie" appearance="outline">
    <label>Rifeiro</label>
    <input matInput placeholder="Rifeiro" [value]="raffle.bookie.name" readonly />
  </mat-form-field>

  <mat-form-field id="description" appearance="outline">
    <label>Descrição</label>
    <textarea [rows]="5" matInput placeholder="Descrição" [value]="raffle.description_award" readonly></textarea>
  </mat-form-field>

  <mat-form-field id="create_at" appearance="outline">
    <label>Criado em</label>
    <input matInput placeholder="Criado em" [value]="raffle.create_at | date: 'dd/MM/yyyy'" readonly />
  </mat-form-field>

  <mat-form-field id="draw_prediction" appearance="outline">
    <label>Previsão Sorteio</label>
    <input matInput placeholder="Previsão Sorteio" [value]="raffle.draw_prediction | date: 'dd/MM/yyyy'" readonly />
  </mat-form-field>

  <mat-form-field id="draw_date" appearance="outline">
    <label>Sorteio</label>
    <input matInput placeholder="Sorteio" [value]="raffle.draw_date ? (raffle.draw_date | date: 'dd/MM/yyyy') : 'Não sorteada'" readonly />
  </mat-form-field>

  @if (raffle.status === "PENDING") {
    <div id="buttons" class="flex width-100 space-between">
      <ion-button color="danger" (click)="deleteRaffle()">
        <ion-icon slot="start" name="trash-outline" />
        <span>Excluir</span>
      </ion-button>

      <ion-button (click)="approveRaffle()">
        <ion-icon slot="start" name="lock-open-outline" />
        <span>Liberar</span>
      </ion-button>
    </div>
  }
</ion-list>

import { Injectable } from '@angular/core';
import { Storage, uploadBytesResumable, uploadString, getDownloadURL, ref, deleteObject } from '@angular/fire/storage';

@Injectable({
  providedIn: 'root'
})
export class FirebaseStorageService {

  constructor(private fbStorage: Storage) {}

  uploadFile(path: string, file: File) {
    const fileRef = ref(this.fbStorage, path);
    return uploadBytesResumable(fileRef, file);
  }

  uploadFromBase64(path: string, base64: string) {
    const fileRef = ref(this.fbStorage, path);
    return uploadString(fileRef, base64, 'data_url');
  }

  getDownloadURL(path: string) {
    const fileRef = ref(this.fbStorage, path);
    return getDownloadURL(fileRef);
  }

  deleteFile(path: string) {
    const fileRef = ref(this.fbStorage, path);
    return deleteObject(fileRef);
  }
}

<ion-content>
  <breadcrumbs [breadcrumbs]="breadcrumbs" />

  <div id="segments">
    <ion-segment [scrollable]="true" [(ngModel)]="activeSegment" mode="md" (ionChange)="segmentChanged()">
      @for (segment of segments; track $index) {
        <ion-segment-button [value]="segment.value">
          <ion-label> {{ segment.label }} </ion-label>
        </ion-segment-button>
      }
    </ion-segment>

    @if (activeSegment === "raffles") {
      <ion-segment [scrollable]="true" [(ngModel)]="statusRaffle" mode="md" (ionChange)="selectedStatusChanged()">
        @for (status of statusRaffleOptionsArr; track $index) {
          <ion-segment-button [value]="status.value">
            <ion-label> {{ status.label }} </ion-label>
          </ion-segment-button>
        }
      </ion-segment>
    }
  </div>

  <div id="content">
    @switch (activeSegment) {
      @case ("details") {
        @if (bookie) {
          <bookie-form-add [bookie]="bookie" (listRaffles)="listRaffles($event)" />
        } @else {
          <bookie-form-add-skeleton />
        }
      }

      @case ("raffles") {
        <raffles-list [raffles]="raffles" app="admin" />
      }
    }
  </div>
</ion-content>
